import { UpdateIcon } from '@radix-ui/react-icons';
import { Box, Button, Flex } from '@radix-ui/themes';
import { CommonDetails } from 'components/common/details';
import { ErrorBoundary } from 'components/common/error-boundary';
import { CommonTextareaInput } from 'components/common/form/textarea';
import { format } from 'date-fns-tz';
import { LOCAL_DATETIME_FORMAT, LOCAL_TIMEZONE } from 'enums/env';
import { t } from 'i18next';
import { RADIX } from 'lib_ts/enums/radix-ui';
import React from 'react';
import { AuthService } from 'services/auth.service';

interface IProps {}

interface IState {
  updated?: Date;
  summary?: any[];
}

export class GameStatusSummary extends React.Component<IProps, IState> {
  private init = false;

  constructor(props: IProps) {
    super(props);

    this.state = {};

    this.refresh = this.refresh.bind(this);
  }

  componentDidMount(): void {
    if (this.init) {
      return;
    }

    this.init = true;

    this.refresh();
  }

  private refresh() {
    AuthService.getInstance()
      .getGameStatuses()
      .then((result) => {
        this.setState({
          updated: new Date(),
          summary: result.sort((a, b) =>
            ((a.team as string) ?? '').localeCompare((b.team as string) ?? '')
          ),
        });
      });
  }

  render() {
    const ts = this.state.updated
      ? format(this.state.updated, LOCAL_DATETIME_FORMAT, {
          timeZone: LOCAL_TIMEZONE,
        })
      : undefined;

    const summary = `Statuses ${ts ? `as of ${ts}` : undefined}`;

    return (
      <ErrorBoundary componentName="GameStatusSummary">
        <CommonDetails summary={summary} gap="0">
          <Flex mt="4" direction="column" gap={RADIX.FLEX.GAP.SM}>
            <Box>
              <Button
                color={RADIX.COLOR.NEUTRAL}
                onClick={() => this.refresh()}
              >
                <UpdateIcon /> {t('common.refresh')}
              </Button>
            </Box>

            <Box>
              <CommonTextareaInput
                id="game-status-summary"
                className="font-mono"
                value={JSON.stringify(this.state.summary, null, 2)}
                rows={20}
                disabled
              />
            </Box>
          </Flex>
        </CommonDetails>
      </ErrorBoundary>
    );
  }
}
