import { Box, Code, Strong } from '@radix-ui/themes';
import { NotifyHelper } from 'classes/helpers/notify.helper';
import { WebSocketHelper } from 'classes/helpers/web-socket.helper';
import { CommonDialog } from 'components/common/dialogs';
import { ErrorBoundary } from 'components/common/error-boundary';
import { DEFAULT_WAIT_SECONDS } from 'components/main/listeners/queue';
import { MachineContext } from 'contexts/machine.context';
import { IBaseDialog } from 'interfaces/i-dialogs';
import { WsMsgType } from 'lib_ts/enums/machine-msg.enum';
import { RADIX } from 'lib_ts/enums/radix-ui';
import { IQueueMsg } from 'lib_ts/interfaces/i-machine-msg';
import React from 'react';
import { WebSocketService } from 'services/web-socket.service';

const COMPONENT_NAME = 'MachineControlDialog';

interface IProps extends IBaseDialog {}

interface IState {
  /** before taking control by force */
  seconds_remaining: number;
}

export class MachineControlDialog extends React.Component<IProps, IState> {
  private requestControlInterval: any;

  constructor(props: IProps) {
    super(props);

    this.state = {
      seconds_remaining: 0,
    };

    this.handleConfirmation = this.handleConfirmation.bind(this);
    this.handleResponse = this.handleResponse.bind(this);
  }

  /** when requesting control and the active user responds in time */
  private handleResponse(event: CustomEvent) {
    const data: IQueueMsg = event.detail;

    switch (data.action) {
      case 'active-accept': {
        /** active user accepted your request */
        NotifyHelper.success({
          message_md: 'Access request accepted, transferring control...',
        });
        break;
      }

      case 'active-reject': {
        /** active user accepted your request */
        NotifyHelper.warning({
          message_md:
            'The active user has rejected your request, please try again later.',
        });
        break;
      }

      default: {
        break;
      }
    }

    this.props.onClose();
  }

  componentDidMount() {
    WebSocketHelper.on(WsMsgType.Misc_ControlResponse, this.handleResponse);
  }

  componentWillUnmount() {
    clearInterval(this.requestControlInterval);
    WebSocketHelper.remove(WsMsgType.Misc_ControlResponse, this.handleResponse);
  }

  private handleConfirmation(confirmed: boolean) {
    if (confirmed) {
      WebSocketService.send(WsMsgType.Misc_ControlRequest, {}, 'SidebarFooter');

      this.setState({ seconds_remaining: DEFAULT_WAIT_SECONDS }, () => {
        clearInterval(this.requestControlInterval);

        this.requestControlInterval = setInterval(() => {
          const nextRemaining = this.state.seconds_remaining - 1;
          this.setState({ seconds_remaining: nextRemaining }, () => {
            if (nextRemaining > 0) {
              return;
            }

            // take control
            WebSocketService.send(
              WsMsgType.Misc_ControlForce,
              {},
              'SidebarFooter'
            );

            this.props.onClose();
          });
        }, 1_000);
      });
      return;
    }

    if (this.state.seconds_remaining > 0) {
      NotifyHelper.warning({
        message_md: 'Please wait for the access request to resolve first.',
      });
      return;
    }

    this.props.onClose();
  }

  render() {
    return (
      <ErrorBoundary componentName={COMPONENT_NAME}>
        <MachineContext.Consumer>
          {(machineCx) => (
            <CommonDialog
              identifier={COMPONENT_NAME}
              title="Machine Control Request"
              width={RADIX.DIALOG.WIDTH.SM}
              content={
                <Box>
                  {this.state.seconds_remaining > 0 ? (
                    <p>
                      Please wait{' '}
                      <Strong>
                        {this.state.seconds_remaining === 1
                          ? '1 more second'
                          : `${this.state.seconds_remaining} more seconds`}
                      </Strong>{' '}
                      for active user <Code>{machineCx.activeUser}</Code> to
                      respond.
                    </p>
                  ) : (
                    <>
                      <p>
                        The active user <Code>{machineCx.activeUser}</Code> will
                        be disconnected if they accept (or fail to decline) your
                        request.
                      </p>
                      <p>
                        Please ensure <Code>{machineCx.machine.machineID}</Code>{' '}
                        is not in use.
                      </p>
                    </>
                  )}
                </Box>
              }
              buttons={[
                {
                  label: 'Request Control',
                  disabled: this.state.seconds_remaining > 0,
                  onClick: () => this.handleConfirmation(true),
                },
                {
                  label: 'Cancel',
                  onClick: () => this.handleConfirmation(false),
                },
              ]}
              onClose={function (): void {
                throw new Error('Function not implemented.');
              }}
            />
          )}
        </MachineContext.Consumer>
      </ErrorBoundary>
    );
  }
}
