import { Button } from '@radix-ui/themes';
import { CommonMenu } from 'components/common/menu';
import { AuthContext } from 'contexts/auth.context';
import { t } from 'i18next';
import { IMenuAction } from 'interfaces/i-menus';
import { LanguageCode } from 'lib_ts/enums/auth.enums';
import { IOption } from 'lib_ts/interfaces/common/i-option';
import React from 'react';
import i18n from 'translations/i18n';

export const LANGUAGE_OPTIONS: IOption[] = [
  { label: 'ENG', value: 'en' },
  { label: '日本語', value: 'ja' },
  { label: '한국', value: 'ko' },
];

interface IProps {
  className?: string;
}

export class CommonLanguagePicker extends React.Component<IProps> {
  render() {
    const current =
      LANGUAGE_OPTIONS.find((l) => l.value === i18n.language) ??
      LANGUAGE_OPTIONS[0];

    return (
      <AuthContext.Consumer>
        {(authCx) => (
          <CommonMenu
            title={t('common.change-language')}
            side="top"
            trigger={<Button variant="ghost">{current.label}</Button>}
            actions={LANGUAGE_OPTIONS.map((language) => {
              const mAction: IMenuAction = {
                label: language.label,
                color: language.value === current.value ? 'green' : undefined,
                onClick: () => {
                  i18n.changeLanguage(language.value);

                  authCx.updateUser({
                    _id: authCx.current.userID,
                    language: language.value as LanguageCode,
                  });
                },
              };

              return mAction;
            })}
          />
        )}
      </AuthContext.Consumer>
    );
  }
}
