import {
  CopyIcon,
  DownloadIcon,
  ExclamationTriangleIcon,
  Share1Icon,
  StarFilledIcon,
  TrashIcon,
  UploadIcon,
} from '@radix-ui/react-icons';
import { Box, Flex, Grid, Spinner, Text } from '@radix-ui/themes';
import { NotifyHelper } from 'classes/helpers/notify.helper';
import { HELP_URLS } from 'classes/helpers/url.helper';
import { HelpCallout } from 'components/common/callouts/help';
import { SuperAdminIcon } from 'components/common/custom-icon/shorthands';
import { CommonConfirmationDialog } from 'components/common/dialogs/confirmation';
import { ErrorBoundary } from 'components/common/error-boundary';
import { CommonFileUploader } from 'components/common/file-uploader';
import { FlexTableWrapper } from 'components/common/layout/flex-table-wrapper';
import { CommonTable } from 'components/common/table';
import { CommonTableButton } from 'components/common/table/button';
import { SectionHeader } from 'components/sections/header';
import { VideoEditorDialog } from 'components/sections/video-library/video-editor';
import env from 'config';
import { IAuthContext } from 'contexts/auth.context';
import { ICookiesContext } from 'contexts/cookies.context';
import { DirtyContext, DirtyProvider } from 'contexts/dirty.context';
import { IGlobalContext } from 'contexts/global.context';
import {
  CheckedContext,
  CheckedProvider,
} from 'contexts/layout/checked.context';
import { IMachineContext, MachineContext } from 'contexts/machine.context';
import { DirtyForm, ISectionsContext } from 'contexts/sections.context';
import { VideosFilterDateAdded } from 'contexts/videos/components/filter-date-added';
import { VideosFilterDelivery } from 'contexts/videos/components/filter-delivery';
import { VideosFilterPitchType } from 'contexts/videos/components/filter-pitch-type';
import { VideosFilterPitcher } from 'contexts/videos/components/filter-pitcher';
import { IVideosContext, STATIC_PREFIX } from 'contexts/videos/videos.context';
import lightFormat from 'date-fns/lightFormat';
import { ACTIONS_KEY, TABLES } from 'enums/tables';
import { t } from 'i18next';
import { TableIdentifier } from 'interfaces/cookies/i-app.cookie';
import {
  IDisplayCol,
  IOnKeyActionDict,
  ITablePageable,
} from 'interfaces/i-tables';
import { MiscHelper } from 'lib_ts/classes/misc.helper';
import { VideoHelper } from 'lib_ts/classes/video.helper';
import { UserRole } from 'lib_ts/enums/auth.enums';
import { ERROR_MSGS } from 'lib_ts/enums/errors.enums';
import { PitcherHand } from 'lib_ts/enums/pitches.enums';
import { RADIX } from 'lib_ts/enums/radix-ui';
import { IMachine } from 'lib_ts/interfaces/i-machine';
import { IVideo } from 'lib_ts/interfaces/i-video';
import React from 'react';
import { VideosService } from 'services/videos.service';

const IDENTIFIER = TableIdentifier.VideoList;

const FLAG_BY_RESOLUTION = env.production;

/** 10 inch mound */
const MOUND_HT_FT = 10 / 12;

const MAX_VIDEO_SIZE_MB = 50;

const getPixelsPerFoot = (v: IVideo) => {
  if (
    isNaN(v.ReleasePixelY) ||
    isNaN(v.MoundPixelY) ||
    isNaN(v.ReleaseHeight)
  ) {
    /** insufficient data, return "bad" result */
    return 0;
  } else {
    const height = Math.abs(v.ReleaseHeight - MOUND_HT_FT);
    return Math.abs(v.ReleasePixelY - v.MoundPixelY) / Math.max(height, 1e-9);
  }
};

interface IProps {
  globalCx: IGlobalContext;
  cookiesCx: ICookiesContext;
  authCx: IAuthContext;
  machineCx: IMachineContext;
  sectionsCx: ISectionsContext;
  videosCx: IVideosContext;
}

interface IDialogs {
  dialogEdit?: number;
  dialogDelete?: number;
}

interface IState extends IDialogs {
  selectedVideos: IVideo[];

  selected?: IVideo;

  videoProgress: number;
  videoProgressLabel: string;

  disableNext: boolean;
  disablePrev: boolean;
}

const DEFAULT_STATE: IState = {
  selectedVideos: [],

  videoProgress: 0,
  videoProgressLabel: '',
  disableNext: false,
  disablePrev: false,
};

const FILE_TYPES = [
  'text/csv',
  'video/mp4', //mp4 and m4v
  // 'video/quicktime',//mov
  // 'video/x-msvideo',//avi
];

const PAGE_SIZES = TABLES.PAGE_SIZES.LG;

export class VideoLibrary extends React.Component<IProps, IState> {
  private tableNode?: CommonTable;
  private fileInput?: CommonFileUploader;

  constructor(props: IProps) {
    super(props);

    this.state = DEFAULT_STATE;

    this.getOnKeyActions = this.getOnKeyActions.bind(this);
    this.getPagination = this.getPagination.bind(this);

    this.handleNext = this.handleNext.bind(this);
    this.handlePrev = this.handlePrev.bind(this);
    this.handleVideoProgress = this.handleVideoProgress.bind(this);

    this.renderDeleteDialog = this.renderDeleteDialog.bind(this);
    this.renderEditDialog = this.renderEditDialog.bind(this);
    this.renderVideoExtraActions = this.renderVideoExtraActions.bind(this);
    this.renderTable = this.renderTable.bind(this);
  }

  private readonly BASE_COLUMNS: IDisplayCol[] = [
    {
      label: 'common.actions',
      key: ACTIONS_KEY,
      actions: [
        {
          label: 'common.edit',
          onClick: (video: IVideo) => {
            this.setState({
              selected: video,
              dialogEdit: Date.now(),
            });
          },
        },
        {
          label: 'common.set-as-default',
          color: RADIX.COLOR.WARNING,
          onClick: (video: IVideo) => {
            const errors = VideoHelper.getErrors(video);
            if (errors.length > 0) {
              NotifyHelper.warning({
                message_md: `Please resolve this video's issues before setting it as a default.`,
              });
              return;
            }

            const payload: Partial<IMachine> = {
              _id: this.props.machineCx.machine._id,
            };

            const isLHP = video.ReleaseSide > 0;
            if (isLHP) {
              payload.default_video_lhp = video._id;
            } else {
              payload.default_video_rhp = video._id;
            }

            this.props.machineCx.update(payload).then(() => {
              NotifyHelper.info({
                message_md: `Updated default ${
                  isLHP ? PitcherHand.LHP : PitcherHand.RHP
                } video for \`${this.props.machineCx.machine.machineID}\`.`,
              });
            });
          },
        },
        {
          label: 'common.duplicate',
          onClick: (video: IVideo) =>
            this.props.videosCx.copyVideos([video._id]),
        },
        {
          label: 'common.download',
          icon: <SuperAdminIcon />,
          onClick: (video: IVideo) => {
            NotifyHelper.info({
              message_md: 'Preparing your download, please wait...',
            });
            this.props.videosCx
              .getCachedPlayback(video._id)
              .then((playback) => {
                if (playback?.video.url) {
                  window.open(playback.video.url);
                }
              });
          },
          disableFn: (video: IVideo) => !video.video_path,
          invisibleFn: () => this.props.authCx.current.role !== UserRole.admin,
        },
        {
          label: 'common.delete',
          color: RADIX.COLOR.DANGER,
          disableFn: (video: IVideo) =>
            video.video_path.startsWith(STATIC_PREFIX),
          onClick: (video: IVideo) => {
            this.setState({
              selectedVideos: [video],
              dialogDelete: Date.now(),
            });
          },
        },
      ],
    },
    {
      label: 'common.status',
      key: '_issues',
      align: 'center',
      thClassNameFn: () => 'width-80px',
      sortRowsFn: (a: IVideo, b: IVideo, dir: number) => {
        const aDefault =
          this.props.machineCx.machine.default_video_lhp === a._id ||
          this.props.machineCx.machine.default_video_rhp === a._id;
        const bDefault =
          this.props.machineCx.machine.default_video_lhp === b._id ||
          this.props.machineCx.machine.default_video_rhp === b._id;

        if (aDefault !== bDefault) {
          return dir * (aDefault ? -1 : 1);
        }

        const aErr = VideoHelper.getErrors(a);
        const bErr = VideoHelper.getErrors(b);
        return dir * (aErr.length > bErr.length ? -1 : 1);
      },
      formatFn: (v: IVideo) => {
        const errors = VideoHelper.getErrors(v);
        if (errors.length > 0) {
          return (
            <Text title={errors[0]}>
              <ExclamationTriangleIcon
                style={{ marginTop: RADIX.ICON.TABLE_MT }}
              />
            </Text>
          );
        }

        if (v._id === this.props.machineCx.machine.default_video_lhp) {
          return (
            <Text
              color={RADIX.COLOR.WARNING}
              title={`Default ${PitcherHand.LHP} Video`}
            >
              <StarFilledIcon style={{ marginTop: RADIX.ICON.TABLE_MT }} />
            </Text>
          );
        }

        if (v._id === this.props.machineCx.machine.default_video_rhp) {
          return (
            <Text
              color={RADIX.COLOR.WARNING}
              title={`Default ${PitcherHand.RHP} Video`}
            >
              <StarFilledIcon style={{ marginTop: RADIX.ICON.TABLE_MT }} />
            </Text>
          );
        }

        return '';
      },
    },
    {
      label: 'common.created',
      key: '_created',
      dataType: 'date',
    },
    {
      label: 'common.file-name',
      key: 'VideoFileName',
      sortRowsFn: (a: IVideo, b: IVideo, dir: number) =>
        -(a.VideoFileName ?? '').localeCompare(b.VideoFileName ?? '') * dir,
    },
    {
      label: 'common.title',
      key: 'VideoTitle',
      sortRowsFn: (a: IVideo, b: IVideo, dir: number) =>
        -(a.VideoTitle ?? '').localeCompare(b.VideoTitle ?? '') * dir,
    },
    {
      label: 'common.pitcher',
      key: 'PitcherFullName',
      sortRowsFn: (a: IVideo, b: IVideo, dir: number) =>
        -(a.PitcherFullName ?? '').localeCompare(b.PitcherFullName ?? '') * dir,
    },
    {
      label: 'common.team',
      key: 'TeamName',
      sortRowsFn: (a: IVideo, b: IVideo, dir: number) =>
        -(a.TeamName ?? '').localeCompare(b.TeamName ?? '') * dir,
    },
    {
      label: 'common.type',
      key: 'PitchType',
      align: 'center',
      sortRowsFn: (a: IVideo, b: IVideo, dir: number) =>
        -(a.PitchType ?? '').localeCompare(b.PitchType ?? '') * dir,
    },
    {
      label: 'videos.pitch-delivery-type',
      key: 'DeliveryType',
      sortRowsFn: (a: IVideo, b: IVideo, dir: number) =>
        -(a.DeliveryType ?? '').localeCompare(b.DeliveryType ?? '') * dir,
    },
    {
      label: 'videos.fps',
      key: 'fps',
      align: 'right',
      formatFn: (v: IVideo) => {
        return isNaN(v.fps) ? undefined : v.fps.toFixed(2);
      },
      colorFn: (v: IVideo) => {
        if (v.fps >= 59.94) {
          return RADIX.COLOR.SUCCESS;
        }

        if (v.fps >= 24) {
          return RADIX.COLOR.WARNING;
        }

        return RADIX.COLOR.DANGER;
      },
    },
    {
      label: 'videos.resolution',
      key: 'resolution',
      sortRowsFn: (a: IVideo, b: IVideo, dir: number) => {
        if (a.cap_size_1 === b.cap_size_1) {
          return dir * (a.cap_size_0 > b.cap_size_0 ? -1 : 1);
        }

        return dir * (a.cap_size_1 > b.cap_size_1 ? -1 : 1);
      },
      formatFn: (v: IVideo) => `${v.cap_size_1}x${v.cap_size_0}`,
      colorFn: (v: IVideo) => {
        if (FLAG_BY_RESOLUTION) {
          if (v.cap_size_0 >= 1080) {
            return RADIX.COLOR.SUCCESS;
          }

          if (v.cap_size_0 >= 720) {
            return RADIX.COLOR.WARNING;
          }

          return RADIX.COLOR.DANGER;
        }

        const p = getPixelsPerFoot(v);
        if (p >= 120) {
          return RADIX.COLOR.SUCCESS;
        }

        if (p >= 80) {
          return RADIX.COLOR.WARNING;
        }

        return RADIX.COLOR.DANGER;
      },
    },
    {
      label: 'common.updated',
      key: '_changed',
      dataType: 'datetime',
    },
    {
      label: 'common.updated-by',
      key: 'changer',
      sortRowsFn: (a: IVideo, b: IVideo, dir: number) =>
        -(a.changer ?? '').localeCompare(b.changer ?? '') * dir,
    },
    {
      label: 'common.shared',
      key: 'original_id',
      align: 'center',
      formatFn: (v: IVideo) =>
        v.original_id ? (
          <Text title="This video was shared with your team">
            <Share1Icon style={{ marginTop: RADIX.ICON.TABLE_MT }} />
          </Text>
        ) : (
          ''
        ),
      sortRowsFn: (a: IVideo, b: IVideo, dir: number) =>
        ((a.original_id ? 1 : 0) > (b.original_id ? 1 : 0) ? -1 : 1) * dir,
    },
  ];

  /** exports all of user's videos as CSV file */
  private exportCSV(mode: 'checked' | 'all') {
    const ids = (() => {
      const filtered = this.props.videosCx.filtered;

      switch (mode) {
        case 'all': {
          if (filtered.length === this.props.videosCx.videos.length) {
            /** no filters applied, export everything using an empty ids list */
            return [];
          }

          /** export all filtered videos */
          return filtered.map((v) => v._id);
        }

        case 'checked': {
          return filtered.filter((v) => v._checked).map((v) => v._id);
        }

        default: {
          return ['none'];
        }
      }
    })();

    VideosService.getInstance()
      .exportCSV(ids)
      .then((csvString) => {
        const blob = new Blob([csvString], { type: 'text/csv' });
        MiscHelper.saveAs(
          blob,
          `video-library_${mode}_${lightFormat(new Date(), 'yyyy-MM-dd')}.csv`
        );
      });
  }

  private async onCSVChange(files: File[]): Promise<void> {
    NotifyHelper.info({
      message_md: 'CSV import started. Please wait as this may take awhile...',
      delay_ms: 15_000,
    });

    return this.props.videosCx.uploadVideosCSV(files).then((success) => {
      if (success) {
        NotifyHelper.success({
          message_md: 'CSV import processed successfully!',
        });
      } else {
        NotifyHelper.error({
          message_md: `CSV import was not processed successfully. ${ERROR_MSGS.CONTACT_SUPPORT}`,
        });
      }
    });
  }

  private handleVideoProgress(ev: ProgressEvent) {
    if (ev.total === 0) {
      return;
    }

    const percent = (100 * ev.loaded) / ev.total;

    this.setState({
      videoProgress: percent,
      videoProgressLabel:
        ev.loaded < ev.total
          ? `${percent.toFixed(0)}%`
          : 'Upload complete, processing...',
    });
  }

  private handleNext() {
    this.tableNode?.goToNextPrevious(1, false);
  }

  private handlePrev() {
    this.tableNode?.goToNextPrevious(-1, false);
  }

  /** only triggered by uploader if at least 1 selected file is valid (based on format + size) */
  private async onFilesChange(files: File[]): Promise<void> {
    if (files.filter((f) => f.type !== 'text/csv').length === 0) {
      // the upload is an import file for updating existing video metadata
      this.onCSVChange(files);
      return;
    }

    /** prevent/warn users before leaving if uploads are still processing */
    this.props.sectionsCx.markDirtyForm(DirtyForm.VideoLibrary);
    return this.props.videosCx
      .uploadVideos(files, this.handleVideoProgress)
      .then(() => {
        /** hide the upload bar after a pause */
        setTimeout(() => {
          this.setState({
            videoProgress: DEFAULT_STATE.videoProgress,
            videoProgressLabel: DEFAULT_STATE.videoProgressLabel,
          });
        }, 2_000);
      })
      .finally(() =>
        this.props.sectionsCx.clearDirtyForm(DirtyForm.VideoLibrary)
      );
  }

  private getOnKeyActions(): IOnKeyActionDict {
    const result: IOnKeyActionDict = {
      Delete: (video: IVideo) => {
        if (video) {
          this.setState({
            selectedVideos: [video],
            dialogDelete: Date.now(),
          });
        }
      },
    };

    return result;
  }

  private getPagination(videos: IVideo[]): ITablePageable {
    const result: ITablePageable = {
      total: videos.length,
      enablePagination: true,
      pageSize: this.props.cookiesCx.getPageSize(IDENTIFIER) ?? PAGE_SIZES[0],
      pageSizeOptions: PAGE_SIZES,
      pageSizeCallback: (value) =>
        this.props.cookiesCx.setPageSize(IDENTIFIER, value),
    };
    return result;
  }

  private renderVideoExtraActions() {
    const filteredChecked = this.props.videosCx.filtered.filter(
      (v) => v._checked
    );

    return (
      <Flex gap={RADIX.FLEX.GAP.SM}>
        <Box flexGrow="1">{this.props.videosCx.loading && <Spinner />}</Box>

        <CommonTableButton
          icon={<DownloadIcon />}
          label="common.export-all"
          onClick={() => this.exportCSV('all')}
        />

        {filteredChecked.length > 0 && (
          <>
            <CommonTableButton
              icon={<DownloadIcon />}
              label="common.export-checked"
              onClick={() => this.exportCSV('checked')}
            />

            <CommonTableButton
              icon={<TrashIcon />}
              label="common.delete-checked"
              color={RADIX.COLOR.DANGER}
              onClick={() => {
                const nonStaticVideos = this.props.videosCx.filtered.filter(
                  (v) => v._checked && !v.video_path.startsWith(STATIC_PREFIX)
                );

                if (nonStaticVideos.length === 0) {
                  NotifyHelper.warning({
                    message_md: `None of the checked videos can be deleted.`,
                  });
                  return;
                }

                this.setState({
                  selectedVideos: nonStaticVideos,
                  dialogDelete: Date.now(),
                });
              }}
            />

            <CommonTableButton
              icon={<CopyIcon />}
              label="common.duplicate-checked"
              color={RADIX.COLOR.WARNING}
              onClick={() =>
                this.props.videosCx.copyVideos(
                  filteredChecked.map((v) => v._id)
                )
              }
            />
          </>
        )}
      </Flex>
    );
  }

  private renderTable() {
    const pagination = this.getPagination(this.props.videosCx.filtered);
    const keyActions = this.getOnKeyActions();

    return (
      <CheckedProvider data={this.props.videosCx.filtered}>
        <CheckedContext.Consumer>
          {(checkedCx) => (
            <CommonTable
              ref={(elem) => (this.tableNode = elem as CommonTable)}
              id="VideoLibrary"
              data-testid="VideoLibrary"
              rowTestLocatorFn={(v: IVideo) =>
                VideoHelper.getErrors(v).length === 0
                  ? 'no-issues'
                  : 'has-issues'
              }
              checkedCx={checkedCx}
              displayColumns={this.BASE_COLUMNS}
              displayData={this.props.videosCx.filtered}
              toolbarContent={
                <Grid columns="4" gap={RADIX.FLEX.GAP.SM}>
                  <Box>
                    <VideosFilterPitcher
                      onChange={() => checkedCx.checkAll(false)}
                    />
                  </Box>
                  <Box>
                    <VideosFilterPitchType
                      onChange={() => checkedCx.checkAll(false)}
                    />
                  </Box>
                  <Box>
                    <VideosFilterDelivery
                      onChange={() => checkedCx.checkAll(false)}
                    />
                  </Box>
                  <Box>
                    <VideosFilterDateAdded
                      onChange={() => checkedCx.checkAll(false)}
                    />
                  </Box>
                </Grid>
              }
              afterSelectRow={async (config: {
                model: IVideo;
                disableNext: boolean;
                disablePrev: boolean;
              }) =>
                this.setState({
                  selected: config.model,
                  disableNext: config.disableNext,
                  disablePrev: config.disablePrev,
                })
              }
              onKeyActions={keyActions}
              checkboxColumnIndex={0}
              suspendKeyListener={this.props.globalCx.dialogs.length > 0}
              {...pagination}
              defaultSortKey="_created"
              defaultSortDir={1}
              enableSort
              vFlex
            />
          )}
        </CheckedContext.Consumer>
      </CheckedProvider>
    );
  }

  private renderDeleteDialog() {
    if (!this.state.dialogDelete) {
      return;
    }

    const videos = this.state.selectedVideos;
    if (videos.length === 0) {
      return;
    }

    return (
      <CommonConfirmationDialog
        key={this.state.dialogDelete}
        identifier="VideoLibraryDeleteDialog"
        maxWidth={RADIX.DIALOG.WIDTH.MD}
        title={t('common.delete-x', {
          x: t(videos.length === 1 ? 'videos.video' : 'videos.videos'),
        }).toString()}
        content={
          <Box>
            <p>
              {t('common.confirm-remove-n-x', {
                n: videos.length,
                x: t(videos.length === 1 ? 'videos.video' : 'videos.videos'),
              })}
            </p>
            <ul>
              {videos.map((v) => (
                <li key={`del-video-${v._id}`}>
                  {v.VideoTitle || v.VideoFileName}
                </li>
              ))}
            </ul>
            <p>{t('videos.orphaned-pitches-warning')}</p>
          </Box>
        }
        action={{
          label: 'common.delete',
          color: RADIX.COLOR.DANGER,
          onClick: () => {
            this.props.videosCx
              .deleteVideos(videos.map((v) => v._id))
              .then((success) => {
                if (success) {
                  this.setState({
                    selectedVideos: [],
                  });
                }
              });
          },
        }}
      />
    );
  }

  private renderEditDialog() {
    if (!this.state.dialogEdit) {
      return;
    }

    if (!this.state.selected) {
      return;
    }

    const video = this.state.selected;
    return (
      <DirtyProvider>
        <DirtyContext.Consumer>
          {(dirtyCx) => (
            <MachineContext.Consumer>
              {(machineCx) => (
                <VideoEditorDialog
                  key={this.state.dialogEdit}
                  dirtyCx={dirtyCx}
                  machineCx={machineCx}
                  video_id={video._id}
                  videosCx={this.props.videosCx}
                  onClose={() => this.setState({ dialogEdit: undefined })}
                  handleNext={
                    this.state.disableNext ? undefined : this.handleNext
                  }
                  handlePrev={
                    this.state.disablePrev ? undefined : this.handlePrev
                  }
                />
              )}
            </MachineContext.Consumer>
          )}
        </DirtyContext.Consumer>
      </DirtyProvider>
    );
  }

  render() {
    return (
      <ErrorBoundary componentName="VideoLibrary">
        <FlexTableWrapper
          gap={RADIX.FLEX.GAP.SECTION}
          header={
            <SectionHeader
              header={t('main.video-library')}
              action={{
                icon: <UploadIcon />,
                label: 'Upload Files',
                tooltip: this.fileInput?.getTooltip(),
                onClick: () => this.fileInput?.handleClick(),
              }}
            />
          }
          table={this.renderTable()}
          footer={
            <>
              {this.renderVideoExtraActions()}
              <HelpCallout url={HELP_URLS.VIDEO_LIBRARY} />
            </>
          }
        />

        <CommonFileUploader
          ref={(elem) => (this.fileInput = elem as CommonFileUploader)}
          id="video-uploader"
          maxMB={MAX_VIDEO_SIZE_MB}
          acceptedTypes={FILE_TYPES}
          progress={this.state.videoProgress}
          progressLabel={this.state.videoProgressLabel}
          notifyMode="aggregate"
          onChange={(files) => this.onFilesChange(files)}
          notes={t('videos.upload-videos-tooltip')}
          multiple
          hidden
        />

        {this.renderEditDialog()}
        {this.renderDeleteDialog()}
      </ErrorBoundary>
    );
  }
}
