import { ENV_PROD } from 'environments/i-prod';
import { IEnvConfig } from 'interfaces/i-env-config';

export const ENV_PROD_ASIA: IEnvConfig = {
  ...ENV_PROD,
  region_redirect: false,
  self_url: 'https://jp.trajektsports.app',
  server_url: 'https://jp.trajektsports.app/node',
  ws_url: 'wss://jp.trajektsports.app/wss',
};
