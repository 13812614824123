import { Separator, Text } from '@radix-ui/themes';
import { ErrorBoundary } from 'components/common/error-boundary';
import { CommonSelectInput } from 'components/common/form/select';
import { CommonTextInput } from 'components/common/form/text';
import { SettingForm } from 'components/common/settings-dialog/form';
import { ACTIVE_ALERT } from 'components/common/settings-dialog/main-sections/machine.section';
import { SettingRow } from 'components/common/settings-dialog/row';
import env from 'config';
import { IAuthContext } from 'contexts/auth.context';
import { IMachineContext } from 'contexts/machine.context';
import { t } from 'i18next';
import { RADIX } from 'lib_ts/enums/radix-ui';
import { TrackingDevice } from 'lib_ts/enums/training.enums';
import { IMachine } from 'lib_ts/interfaces/i-machine';
import React from 'react';
import ReactMarkdown from 'react-markdown';

const COMPONENT_NAME = 'MachineTrackingDeviceTab';

interface IProps {
  authCx: IAuthContext;
  machineCx: IMachineContext;
  onSave: (payload: Partial<IMachine>) => void;
}

interface IState {
  value: IMachine;
}

export class MachineTrackingDeviceTab extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      value: { ...props.machineCx.machine },
    };
  }

  render() {
    const active = this.props.machineCx.checkActive(true);

    return (
      <ErrorBoundary componentName={COMPONENT_NAME}>
        <SettingForm>
          {!active && ACTIVE_ALERT}

          {!env.production && (
            <>
              <SettingRow
                header="settings.tracking-device"
                description={<Text>{t('settings.tracking-device-msg')}</Text>}
                input={
                  <CommonSelectInput
                    id="machine-tracking-device"
                    options={Object.values(TrackingDevice).map((o) => ({
                      label: o,
                      value: o,
                    }))}
                    disabled={
                      !active || this.props.authCx.restrictedGameStatus()
                    }
                    value={this.state.value.tracking_device}
                    onChange={(v) =>
                      this.setState(
                        {
                          value: {
                            ...this.state.value,
                            tracking_device: v as TrackingDevice,
                          },
                        },
                        () =>
                          this.props.onSave({
                            _id: this.state.value._id,
                            tracking_device: v as TrackingDevice,
                          })
                      )
                    }
                  />
                }
              />
              <Separator size="4" />
              <SettingRow
                header="settings.trackman-serial"
                description={
                  <>
                    {this.props.machineCx.machine.sandbox ? (
                      <Text>
                        A sandbox's Trackman Serial must match its MachineID.
                      </Text>
                    ) : (
                      <Text color={RADIX.COLOR.WARNING}>
                        TODO: DEPRECATE THIS IF TRACKMAN INTEGRATION DOESN'T USE
                        SERIALS
                      </Text>
                    )}
                  </>
                }
                input={
                  <CommonTextInput
                    id="machine-trackman-serial"
                    value={this.state.value.trackman_serial}
                    disabled={
                      !active ||
                      this.state.value.sandbox ||
                      this.props.authCx.restrictedGameStatus()
                    }
                    onChange={(v) =>
                      this.setState(
                        {
                          value: {
                            ...this.state.value,
                            trackman_serial: (v ?? '').trim(),
                          },
                        },
                        () =>
                          this.props.onSave({
                            _id: this.state.value._id,
                            trackman_serial: this.state.value.trackman_serial,
                          })
                      )
                    }
                  />
                }
              />
              <Separator size="4" />
            </>
          )}

          <SettingRow
            header="settings.rapsodo-serial"
            description={
              <ReactMarkdown
                children={t(
                  this.props.machineCx.machine.sandbox
                    ? 'settings.rapsodo-serial-sandbox-msg'
                    : 'settings.rapsodo-serial-msg'
                )}
              />
            }
            input={
              <CommonTextInput
                id="machine-rapsodo-serial"
                value={this.state.value.rapsodo_serial}
                disabled={
                  !active ||
                  this.state.value.sandbox ||
                  this.props.authCx.restrictedGameStatus()
                }
                onChange={(v) =>
                  this.setState(
                    {
                      value: {
                        ...this.state.value,
                        rapsodo_serial: (v ?? '').trim(),
                      },
                    },
                    () =>
                      this.props.onSave({
                        _id: this.state.value._id,
                        rapsodo_serial: this.state.value.rapsodo_serial,
                      })
                  )
                }
              />
            }
          />
        </SettingForm>
      </ErrorBoundary>
    );
  }
}
