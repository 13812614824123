import { AlertDialog, Button, Flex } from '@radix-ui/themes';
import { t } from 'i18next';
import { IButton } from 'interfaces/i-buttons';
import { RADIX } from 'lib_ts/enums/radix-ui';
import React, { useState } from 'react';

interface IProps {
  identifier: string;
  trigger?: React.ReactNode;
  maxWidth?: string;

  title: string;
  description?: string;
  content?: React.ReactNode;

  cancel?: IButton;
  action: IButton;
}

export const CommonConfirmationDialog = (props: IProps) => {
  const [_open, _setOpen] = useState(true);

  return (
    <AlertDialog.Root open={_open} onOpenChange={(open) => _setOpen(open)}>
      {props.trigger && (
        <AlertDialog.Trigger>{props.trigger}</AlertDialog.Trigger>
      )}
      <AlertDialog.Content
        maxWidth={props.maxWidth ?? RADIX.DIALOG.WIDTH.SM}
        aria-describedby={props.description}
      >
        <AlertDialog.Title>{t(props.title)}</AlertDialog.Title>

        {props.description && (
          <AlertDialog.Description>
            {t(props.description)}
          </AlertDialog.Description>
        )}

        {props.content}

        <Flex gap={RADIX.FLEX.GAP.SM} mt="4" justify="end">
          {!props.cancel?.invisible && (
            <AlertDialog.Cancel>
              <Button
                title={props.cancel?.tooltip}
                onClick={props.cancel?.onClick}
                disabled={props.cancel?.disabled}
                className={props.cancel?.className}
                variant={
                  props.cancel?.variant ?? RADIX.BUTTON.VARIANT.SECONDARY
                }
                color={props.cancel?.color ?? RADIX.COLOR.NEUTRAL}
              >
                {props.cancel?.icon} {t(props.cancel?.label ?? 'common.cancel')}
              </Button>
            </AlertDialog.Cancel>
          )}

          {!props.action.invisible && (
            <AlertDialog.Action>
              <Button
                title={props.action.tooltip}
                onClick={props.action.onClick}
                disabled={props.action.disabled}
                className={props.action.className}
                variant={props.action.variant ?? RADIX.BUTTON.VARIANT.PRIMARY}
                color={props.action.color ?? RADIX.COLOR.WARNING}
              >
                {props.action.icon} {t(props.action.label ?? 'common.confirm')}
              </Button>
            </AlertDialog.Action>
          )}
        </Flex>
      </AlertDialog.Content>
    </AlertDialog.Root>
  );
};
