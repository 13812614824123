import {
  CardStackIcon,
  ChevronUpIcon,
  DotsVerticalIcon,
} from '@radix-ui/react-icons';
import { Box, Flex, IconButton, Spinner, Text } from '@radix-ui/themes';
import { NotifyHelper } from 'classes/helpers/notify.helper';
import { SidebarHelper } from 'classes/helpers/sidebar.helper';
import { HELP_URLS } from 'classes/helpers/url.helper';
import { DropHandle } from 'components/common/drag-drop';
import { ErrorBoundary } from 'components/common/error-boundary';
import { CommonNavMenu } from 'components/common/layout/nav-menu';
import { CommonMenu } from 'components/common/menu';
import { CopyFolderDialog } from 'components/common/pitch-lists/copy-folder';
import { ManageListDialog } from 'components/common/pitch-lists/manage-list';
import { ReassignListsDialog } from 'components/common/pitch-lists/reassign-lists';
import { SidebarNavigationTrigger } from 'components/main/sidebar/nav-trigger';
import { DeleteFolderDialog } from 'components/main/sidebar/pitch-lists/dialogs/delete-folder';
import { RenameFolderDialog } from 'components/main/sidebar/pitch-lists/dialogs/rename-folder';
import { PitchListFolder } from 'components/main/sidebar/pitch-lists/pitch-list-folder';
import { IAuthContext, getLeagueTeams } from 'contexts/auth.context';
import { ICookiesContext } from 'contexts/cookies.context';
import { IMachineContext } from 'contexts/machine.context';
import { IPitchListsContext } from 'contexts/pitch-lists/pitch-lists.context';
import { ISectionsContext } from 'contexts/sections.context';
import { DropContainer } from 'enums/dnd.enums';
import { SectionName } from 'enums/route.enums';
import { t } from 'i18next';
import { IMenuAction } from 'interfaces/i-menus';
import { ISidebarFolder } from 'interfaces/i-sidebar';
import { ArrayHelper } from 'lib_ts/classes/array.helper';
import { UserRole } from 'lib_ts/enums/auth.enums';
import { PitchListExtType } from 'lib_ts/enums/pitches.enums';
import { RADIX } from 'lib_ts/enums/radix-ui';
import {
  ICopyToFrom,
  IPitchList,
  IPitchListPutManyRequest,
} from 'lib_ts/interfaces/pitches';
import React from 'react';
import './index.scss';

const COMPONENT_NAME = 'SidebarPitchLists';

interface IManageFolderRequest extends IPitchListPutManyRequest {
  copy: boolean;
}

interface IProps {
  authCx: IAuthContext;
  cookiesCx: ICookiesContext;
  machineCx: IMachineContext;
  listsCx: IPitchListsContext;
  sectionsCx: ISectionsContext;
}

interface IDialogs {
  dialogCopyFolder?: number;
  dialogCreateList?: number;
  dialogDeleteFolder?: number;
  dialogReassignFolder?: number;
  dialogRenameFolder?: number;
}

interface IState extends IDialogs {
  folders: ISidebarFolder[];

  /** used for defining copy and reassign payloads */
  fromFolder?: ICopyToFrom;

  manageLists?: IPitchList[];
  renameFolder?: ISidebarFolder;
  manageListFolderPayload?: IManageFolderRequest;
  deleteLists?: IPitchList[];
}

export class SidebarPitchLists extends React.Component<IProps, IState> {
  private init = false;

  constructor(props: IProps) {
    super(props);

    this.state = {
      folders: [],
    };

    this.getFolderActions = this.getFolderActions.bind(this);
    this.getMainActions = this.getMainActions.bind(this);

    this.makeRootFolders = this.makeRootFolders.bind(this);

    this.renderFullMenu = this.renderFullMenu.bind(this);
  }

  componentDidMount(): void {
    if (this.init) {
      return;
    }

    this.init = true;

    this.setState({ folders: this.makeRootFolders() });
  }

  componentDidUpdate(prevProps: Readonly<IProps>): void {
    const foldersChanged =
      prevProps.listsCx.lists !== this.props.listsCx.lists ||
      prevProps.authCx.current.placeholder_folders !==
        this.props.authCx.current.placeholder_folders;

    if (foldersChanged) {
      this.setState({ folders: this.makeRootFolders() });
    }
  }

  private makeRootFolders(): ISidebarFolder[] {
    const output = SidebarHelper.makeRootFolders(this.props.listsCx.lists);

    if (!this.props.authCx.current.placeholder_folders) {
      return output;
    }

    const personalRoot = output.find(
      (f) => f.depth === 0 && f._parent_def === 'team-users'
    );

    if (personalRoot) {
      // only add placeholders to the personal root
      const teamNames = getLeagueTeams(this.props.authCx.current.league);
      teamNames.forEach((team) => {
        const pathComponents = [team.value];

        const existingTeam = personalRoot.folders.find((f) =>
          ArrayHelper.equals(f.pathComponents, pathComponents)
        );

        const tFolder: ISidebarFolder = existingTeam ?? {
          depth: 1,
          _parent_def: 'team-users',
          _parent_id: this.props.authCx.current.userID,
          _parent_field: 'lists',

          pathComponents: pathComponents,
          pathDisplay: pathComponents.join('/'),
          pathEnd: pathComponents.slice(-1)[0],

          files: [],
          folders: [],
          placeholder: true,
        };

        if (!existingTeam) {
          // avoid duplicate entries, but continue processing in case the subfolders need to be created
          personalRoot.folders.push(tFolder);
        }

        ['Starters', 'Relievers'].forEach((sub) => {
          const pathComponents = [team.value, sub];

          if (
            tFolder.folders.findIndex((f) =>
              ArrayHelper.equals(pathComponents, f.pathComponents)
            ) !== -1
          ) {
            // avoid duplicate entries
            return;
          }

          const sFolder: ISidebarFolder = {
            depth: 2,
            _parent_def: 'team-users',
            _parent_id: this.props.authCx.current.userID,
            _parent_field: 'lists',

            pathComponents: pathComponents,
            pathDisplay: pathComponents.join('/'),
            pathEnd: pathComponents.slice(-1)[0],

            files: [],
            folders: [],
            placeholder: true,
          };
          tFolder.folders.push(sFolder);
        });

        return tFolder;
      });
    }

    return output;
  }

  private getFolderActions(config: {
    folder: ISidebarFolder;
    container: DropContainer;
  }): IMenuAction[] {
    /** only allow admins to change machine or team lists (i.e. reassign or delete) */
    const canMutate =
      config.container === DropContainer.PersonalFolder ||
      [UserRole.admin, UserRole.team_admin].includes(
        this.props.authCx.current.role
      );

    const folderType = (() => {
      switch (config.container) {
        case DropContainer.TeamFolder: {
          return 'team folders';
        }

        case DropContainer.MachineFolder: {
          return 'machine folders';
        }

        case DropContainer.PersonalFolder: {
          return 'personal folders';
        }

        default: {
          return 'folders';
        }
      }
    })();

    return [
      {
        label: 'Rename Folder',
        invisible: this.props.authCx.restrictedGameStatus(),
        onClick: () =>
          this.setState({
            renameFolder: config.folder,
            dialogRenameFolder: Date.now(),
          }),
      },
      {
        label: 'Duplicate Folder',
        onClick: () => {
          if (!config.folder._parent_def) {
            NotifyHelper.warning({
              message_md: `Invalid parent definition found for container type ${config.container} with ID ${config.folder._parent_id}.`,
            });
            return;
          }

          const folderContents = SidebarHelper.getFilesRecursively(
            config.folder
          );
          if (folderContents.length === 0) {
            NotifyHelper.warning({
              message_md: `No pitch lists found in folder "${config.folder.pathDisplay}".`,
            });
            return;
          }

          this.setState({
            dialogCopyFolder: Date.now(),
            manageLists: folderContents.map((f) => f.object),
            fromFolder: {
              _parent_def: config.folder._parent_def,
              _parent_id: config.folder._parent_id,
              folder: config.folder.pathDisplay,
            },
          });
        },
      },
      {
        label: 'Reassign Folder',
        color: RADIX.COLOR.WARNING,
        onClick: () => {
          if (!canMutate) {
            NotifyHelper.warning({
              message_md: `Only admins can reassign ${folderType}. Please contact your team's admin or support for more assistance.`,
              buttons: [
                {
                  label: t('common.read-more'),
                  onClick: () => window.open(HELP_URLS.PITCH_REASSIGN),
                },
              ],
            });
            return;
          }

          if (!config.folder._parent_def) {
            NotifyHelper.warning({
              message_md: `Invalid parent definition found for container type ${config.container} with ID ${config.folder._parent_id}.`,
            });
            return;
          }

          const folderContents = SidebarHelper.getFilesRecursively(
            config.folder
          );
          if (folderContents.length === 0) {
            NotifyHelper.warning({
              message_md: `No pitch lists found in folder "${config.folder.pathDisplay}".`,
            });
            return;
          }

          this.setState({
            dialogReassignFolder: Date.now(),
            manageLists: folderContents.map((f) => f.object),
            fromFolder: {
              _parent_def: config.folder._parent_def,
              _parent_id: config.folder._parent_id,
              folder: config.folder.pathDisplay,
            },
          });
        },
      },
      {
        label: 'Delete Folder',
        color: RADIX.COLOR.DANGER,
        onClick: () => {
          if (!canMutate) {
            NotifyHelper.warning({
              message_md: `Only admins can delete ${folderType}. Please contact your team's admin or support for more assistance.`,
              buttons: [
                {
                  label: t('common.read-more'),
                  onClick: () => window.open(HELP_URLS.PITCH_REASSIGN),
                },
              ],
            });
            return;
          }

          const folderContents = SidebarHelper.getFilesRecursively(
            config.folder
          );
          if (folderContents.length === 0) {
            NotifyHelper.warning({
              message_md: `No pitch lists found in folder "${config.folder.pathDisplay}".`,
            });
            return;
          }

          this.setState({
            dialogDeleteFolder: Date.now(),
            manageLists: folderContents.map((f) => f.object),
          });
        },
      },
    ];
  }

  private renderRootFolders() {
    return (
      <>
        {this.state.folders.map((folder, iFolder) => {
          const def = SidebarHelper.getDragDropDef(folder);

          return (
            <details key={iFolder} className="SidebarPLFolderDetails" open>
              <summary>
                <Flex gap={RADIX.FLEX.GAP.SM} justify="between">
                  <Box>
                    <DropHandle
                      id={def.id}
                      container={def.container}
                      value={folder.pathDisplay}
                      accept={def.accept}
                    >
                      <Text color={RADIX.COLOR.NEUTRAL} truncate>
                        {SidebarHelper.getFolderName(
                          folder,
                          this.props.machineCx.machine.machineID
                        )}
                      </Text>
                    </DropHandle>
                  </Box>
                  <Flex
                    gap={RADIX.FLEX.GAP.XS}
                    className="SidebarPLFolderActions"
                  >
                    <ChevronUpIcon className="SidebarPLFolderChevron" />
                  </Flex>
                </Flex>
              </summary>

              <PitchListFolder
                machineCx={this.props.machineCx}
                sectionsCx={this.props.sectionsCx}
                listsCx={this.props.listsCx}
                folder={folder}
                container={def.container}
                accept={def.accept}
                getFolderActions={this.getFolderActions}
              />
            </details>
          );
        })}
      </>
    );
  }

  private getMainActions(): IMenuAction[] {
    const group = 'common.actions';

    return [
      {
        label: 'common.view-all',
        group: group,
        onClick: () =>
          this.props.sectionsCx.tryChangeSection({
            trigger: 'sidebar pitch lists menu',
            name: SectionName.PitchLists,
          }),
      },
      {
        label: 'common.create',
        group: group,
        onClick: () => this.setState({ dialogCreateList: Date.now() }),
      },
      {
        label: 'common.refresh',
        group: group,
        onClick: () => this.props.listsCx.refreshLists(),
      },
    ];
  }

  private renderMiniMenu() {
    const lists = this.props.listsCx.lists;
    if (lists.length > 0) {
      const listToAction = (l: IPitchList, group: string): IMenuAction => {
        return {
          group: group,
          label: l.name,
          onClick: () => {
            if (this.props.listsCx.loading) {
              return;
            }

            if (
              !this.props.listsCx.active ||
              this.props.listsCx.active._id !== l._id ||
              this.props.sectionsCx.active.name !== SectionName.PitchList
            ) {
              /** move user to pitch list (invisible) section */
              this.props.sectionsCx.tryChangeSection({
                trigger: 'sidebar, open list',
                name: SectionName.PitchList,
                fragment: l._id,
              });
            }
          },
        };
      };

      const actions: IMenuAction[] = [...this.getMainActions()];

      /** show most recently opened lists at the top */
      const recentIDs = this.props.listsCx.recentIDs;

      const recents = lists
        .filter((l) => recentIDs.includes(l._id))
        .sort((a, b) =>
          recentIDs.findIndex((id) => id === a._id) <
          recentIDs.findIndex((id) => id === b._id)
            ? -1
            : 1
        )
        .map((l) => listToAction(l, t('main.recent-lists')));

      actions.push(...recents);

      /** show all lists sorted alphabetically */
      const groupSortFn = (
        l: IPitchList
      ): {
        sort: number;
        group: string;
      } => {
        switch (l.type) {
          case PitchListExtType.Sample: {
            return {
              sort: 3,
              group: 'main.sample-lists',
            };
          }

          case PitchListExtType.Card: {
            return {
              sort: 5,
              group: 'main.player-cards',
            };
          }

          case PitchListExtType.Reference: {
            return {
              sort: 4,
              group: 'main.reference-lists',
            };
          }

          default: {
            break;
          }
        }

        // no type, use parent
        switch (l._parent_def) {
          case 'teams': {
            return {
              sort: 2,
              group: 'main.team-lists',
            };
          }

          case 'team-machines': {
            return {
              sort: 1,
              group: 'main.machine-lists',
            };
          }

          case 'team-users':
          default: {
            return {
              sort: 0,
              group: 'main.personal-lists',
            };
          }
        }
      };

      actions.push(
        ...lists
          .sort((a, b) => {
            const aDef = groupSortFn(a);
            const bDef = groupSortFn(b);

            if (aDef.sort !== bDef.sort) {
              return aDef.sort < bDef.sort ? -1 : 1;
            }

            // within the same category, sort by folder so folderless stuff goes to top
            return (a.folder ?? '').localeCompare(b.folder ?? '');
          })
          .map((l) => {
            const def = groupSortFn(l);
            const group = t(def.group);
            return listToAction(l, l.folder ? `${group}: ${l.folder}` : group);
          })
      );

      return (
        <CommonNavMenu>
          <SidebarNavigationTrigger
            icon={<CardStackIcon />}
            label="main.pitch-lists"
            actions={actions}
          />
        </CommonNavMenu>
      );
    }
  }

  private renderFullMenu() {
    const loading =
      this.props.listsCx.loading && this.props.listsCx.lists.length === 0;

    return (
      <Flex direction="column" gap={RADIX.FLEX.GAP.SM} p="2">
        <Flex gap={RADIX.FLEX.GAP.SM} justify="between">
          <Box flexGrow="1">
            <Text
              className="cursor-pointer"
              onClick={() =>
                this.props.sectionsCx.tryChangeSection({
                  trigger: 'sidebar pitch lists menu',
                  name: SectionName.PitchLists,
                })
              }
              as="div"
            >
              {t('main.pitch-lists').toUpperCase()}
            </Text>
          </Box>

          <Box>
            <CommonMenu
              title="Manage Pitch Lists"
              trigger={
                <IconButton variant="ghost" color={RADIX.COLOR.NEUTRAL}>
                  <DotsVerticalIcon />
                </IconButton>
              }
              actions={this.getMainActions()}
            />
          </Box>
        </Flex>

        {loading && <Spinner />}

        {!loading && this.renderRootFolders()}
      </Flex>
    );
  }

  render() {
    const menuContent =
      this.props.cookiesCx.app.sidebar_state === 'mini'
        ? this.renderMiniMenu()
        : this.renderFullMenu();

    return (
      <ErrorBoundary
        componentName={COMPONENT_NAME}
        data-testid="SidebarPitchLists"
      >
        {menuContent}

        {this.state.dialogRenameFolder && this.state.renameFolder && (
          <RenameFolderDialog
            key={this.state.dialogRenameFolder}
            folder={this.state.renameFolder}
            onClose={() => this.setState({ dialogRenameFolder: undefined })}
          />
        )}

        {this.state.dialogReassignFolder && this.state.fromFolder && (
          <ReassignListsDialog
            key={this.state.dialogReassignFolder}
            identifier="SidebarPitchListsReassignListsDialog"
            authCx={this.props.authCx}
            listsCx={this.props.listsCx}
            refPayload={{
              filterLists: this.state.manageLists,
              filter: {
                _parent_id: this.state.fromFolder._parent_id,
                _parent_def: this.state.fromFolder._parent_def,
              },
              update: {
                processed: new Date(),
                process_notes: `reassigned from sidebar by user ${this.props.authCx.current.email}`,
              },
            }}
            onClose={() => this.setState({ dialogReassignFolder: undefined })}
          />
        )}

        {this.state.dialogCreateList && (
          <ManageListDialog
            key={this.state.dialogCreateList}
            identifier="SidebarPitchListsCreateListDialog"
            mode="create"
            authCx={this.props.authCx}
            machineCx={this.props.machineCx}
            listsCx={this.props.listsCx}
            onCreated={() => this.setState({ dialogCreateList: undefined })}
            onClose={() => this.setState({ dialogCreateList: undefined })}
          />
        )}

        {this.state.dialogCopyFolder &&
          this.state.fromFolder &&
          this.state.manageLists && (
            <CopyFolderDialog
              key={this.state.dialogCopyFolder}
              identifier="SidebarPitchListsCopyFolderDialog"
              authCx={this.props.authCx}
              listsCx={this.props.listsCx}
              lists={this.state.manageLists}
              from={this.state.fromFolder}
              onClose={() => this.setState({ dialogCopyFolder: undefined })}
            />
          )}

        {this.state.dialogDeleteFolder && this.state.manageLists && (
          <DeleteFolderDialog
            key={this.state.dialogDeleteFolder}
            lists={this.state.manageLists}
          />
        )}
      </ErrorBoundary>
    );
  }
}
