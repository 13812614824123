import { PaperPlaneIcon } from '@radix-ui/react-icons';
import { NotifyHelper } from 'classes/helpers/notify.helper';
import { DialogButton } from 'components/common/dialogs/button';
import { ErrorBoundary } from 'components/common/error-boundary';
import { CommonFormGrid } from 'components/common/form/grid';
import { CommonSelectInput } from 'components/common/form/select';
import { CommonTextareaInput } from 'components/common/form/textarea';
import { RADIX } from 'lib_ts/enums/radix-ui';
import { ICustomRequestMsg } from 'lib_ts/interfaces/i-machine-msg';
import React from 'react';
import { AdminMachinesService } from 'services/admin/machines.service';

const COMPONENT_NAME = 'CustomRequestTab';

interface IProps {
  machineID: string;
}

interface IState {
  dataJSON: string;
}

const PRESETS: {
  id: string;
  label: string;
  data: any;
}[] = [];

// if anything needs to be pre-defined, it goes here
const DEFAULT_PAYLOAD: ICustomRequestMsg = {
  targets: ['submodule1', 'submodule2'],
  value: {
    sample_key: 1,
    another_key: 2,
  },
};

export class CustomRequestTab extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      dataJSON: JSON.stringify(DEFAULT_PAYLOAD, null, 2),
    };
  }

  render() {
    return (
      <ErrorBoundary componentName={COMPONENT_NAME}>
        <CommonFormGrid columns={1}>
          <CommonTextareaInput
            id="custom-request-payload"
            label="Payload JSON"
            name="config"
            rows={10}
            value={this.state.dataJSON}
            className="font-mono"
            onChange={(v) => this.setState({ dataJSON: v ?? '{}' })}
          />
          <CommonSelectInput
            id="custom-request-preset"
            name="preset"
            label="Preset"
            options={PRESETS.map((p) => ({
              label: p.label,
              value: p.id,
            }))}
            onChange={(v) => {
              const preset = PRESETS.find((p) => p.id === v);

              this.setState({
                dataJSON: JSON.stringify(
                  preset?.data ?? DEFAULT_PAYLOAD,
                  null,
                  2
                ),
              });
            }}
            optional
          />
          <DialogButton
            icon={<PaperPlaneIcon />}
            label="Send Request"
            color={RADIX.COLOR.WARNING}
            onClick={() => {
              const value = this.state.dataJSON;

              if (!value) {
                NotifyHelper.warning({
                  message_md: 'Payload value cannot be empty.',
                });
                return;
              }

              const jsonValue: ICustomRequestMsg = JSON.parse(value);

              if (typeof jsonValue !== 'object') {
                NotifyHelper.warning({
                  message_md: 'Payload value must be a JSON object.',
                });
                return;
              }

              AdminMachinesService.getInstance().postCustomRequest(
                this.props.machineID,
                jsonValue
              );
            }}
          />
        </CommonFormGrid>
      </ErrorBoundary>
    );
  }
}
