import format from 'date-fns-tz/format';
import parseISO from 'date-fns/parseISO';
import { EnvIdentifier } from 'enums/env';
import { ENV_BETA } from 'environments/i-beta';
import { ENV_DEV } from 'environments/i-dev';
import { ENV_LOCAL } from 'environments/i-local';
import { ENV_MAINTENANCE } from 'environments/i-maintenance';
import { ENV_PROD } from 'environments/i-prod';
import { ENV_PROD_ASIA } from 'environments/i-prod-asia';
import { ENV_SANDBOX } from 'environments/i-sandbox';
import { ENV_STAGING } from 'environments/i-staging';
import { IEnvConfig } from 'interfaces/i-env-config';
import {
  buildVersion,
  IBuildMetadata,
} from 'lib_ts/interfaces/common/i-build-metadata';

const BUILD_METADATA = (() => {
  try {
    return {
      ...require('./build-metadata.json'),
      ...require('./build-timestamp.json'),
    } as IBuildMetadata;
  } catch (e) {
    console.error(e);

    return {
      ...require('./build-metadata.json'),
      updated: new Date().toISOString(),
    } as IBuildMetadata;
  }
})();

const BUILD_VERSION = buildVersion(BUILD_METADATA);
const BUILD_UPDATED = parseISO(BUILD_METADATA.updated);
const BUILD_UPDATED_DISPLAY = format(BUILD_UPDATED, 'yyyy-MM-dd @ HH:mm', {
  timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
});

/** avoids crashing the app if no file is found */
const localEnvConfig = (): Partial<IEnvConfig> | undefined => {
  try {
    const output = require('./local.config.json');
    return output;
  } catch {
    return {};
  }
};

/** based on target REACT_APP_STAGE from build command */
const mainEnvConfig = (): IEnvConfig => {
  switch (process.env.REACT_APP_STAGE as EnvIdentifier) {
    case 'prod':
      return ENV_PROD;

    case 'prod-asia':
      return ENV_PROD_ASIA;

    case 'staging':
      return ENV_STAGING;

    case 'sandbox':
      return ENV_SANDBOX;

    case 'dev':
      return ENV_DEV;

    case 'maintenance':
      return ENV_MAINTENANCE;

    case 'beta': {
      return ENV_BETA;
    }

    default:
      return ENV_LOCAL;
  }
};

const config: IEnvConfig = {
  ...mainEnvConfig(),
  ...localEnvConfig(),
};

interface IConfig extends IEnvConfig {
  version: string;
  updated: Date;

  /** for displaying version and updated timestamp as string */
  getBuildStamp: () => string;

  termsRevisionDate: string;
}

const output: IConfig = {
  version: BUILD_VERSION,
  updated: BUILD_UPDATED,
  termsRevisionDate: '2024-01-30',

  getBuildStamp: () => `v${BUILD_VERSION} @ ${BUILD_UPDATED_DISPLAY}`,

  /** export conditional config */
  ...config,
};

export default output;
