import { LoginMethod } from 'enums/env';
import { IEnvConfig } from 'interfaces/i-env-config';

export const ENV_DEV: IEnvConfig = {
  identifier: 'dev',
  maintenance: false,
  strict: true,
  use_radix: true,

  production: false,
  debug_messages: true,
  region_redirect: false,
  run_tests: false,

  self_url: 'https://dev.trajektsports.app',
  server_url: 'https://dev.trajektsports.app/node',
  ws_url: 'wss://dev.trajektsports.app/wss',

  limits: {
    max_training_shots: 100,
  },

  login_methods: [LoginMethod.Auth0, LoginMethod.Legacy],

  enable: {
    auto_beta: false,

    change_language: true,

    vitals: false,

    sample_lists: false,

    toggle_rapid: true,

    pitch_upload_avg_release: true,
    pitch_upload_avg_chars: false,

    toggle_plate_shots: false,
    toggle_plate_debug: true,
    toggle_plate_safety_controls: true,
    toggle_plate_ellipses: true,

    fire_tags: false,
  },

  integrations: {
    intercom_app_id: 'tas54ol3',

    auth0: {
      domain: 'trajektsports.us.auth0.com',
      audience: 'https://trajektsports.us.auth0.com/api/v2/',
      clientId: 'DqVE1BEpZTxs7COi7gJVPBU6ckbkNqcS',
    },
  },
};
