import { Flex, Separator, Text } from '@radix-ui/themes';
import { HELP_URLS } from 'classes/helpers/url.helper';
import { ErrorBoundary } from 'components/common/error-boundary';
import { CommonSwitchInput } from 'components/common/form/switch';
import { CommonLink } from 'components/common/link';
import { SettingForm } from 'components/common/settings-dialog/form';
import { SettingRow } from 'components/common/settings-dialog/row';
import { MachineContext } from 'contexts/machine.context';
import { t } from 'i18next';
import { RADIX } from 'lib_ts/enums/radix-ui';
import React from 'react';

const COMPONENT_NAME = 'MachineFiringTab';

interface IProps {}

interface IState {}

export class MachineFiringTab extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {};
  }

  render() {
    return (
      <ErrorBoundary componentName={COMPONENT_NAME}>
        <MachineContext.Consumer>
          {(machineCx) => (
            <SettingForm>
              <SettingRow
                header="settings.rapid-mode"
                description={
                  <Text>
                    {t(
                      machineCx.machine.enable_rapid_mode
                        ? 'settings.rapid-mode-disable-msg'
                        : 'settings.rapid-mode-enable-msg'
                    )}
                  </Text>
                }
                input={
                  <Flex justify="end">
                    <CommonSwitchInput
                      id="firing-rapid-mode"
                      defaultChecked={machineCx.machine.enable_rapid_mode}
                      onCheckedChange={(v) => {
                        machineCx.update({
                          _id: machineCx.machine._id,
                          enable_rapid_mode: v,
                        });
                      }}
                    />
                  </Flex>
                }
              />
              <Separator size="4" />
              <SettingRow
                header="settings.rapsodo-validation"
                description={
                  <Text>
                    {t(
                      machineCx.machine.enable_raspodo_validation
                        ? 'settings.rapsodo-validation-disable-msg'
                        : 'settings.rapsodo-validation-enable-msg'
                    )}
                  </Text>
                }
                input={
                  <Flex justify="end">
                    <CommonSwitchInput
                      id="firing-rapsodo-validation"
                      defaultChecked={
                        machineCx.machine.enable_raspodo_validation
                      }
                      onCheckedChange={(v) => {
                        machineCx.update({
                          _id: machineCx.machine._id,
                          enable_raspodo_validation: v,
                        });
                      }}
                    />
                  </Flex>
                }
              />
              <Separator size="4" />
              <SettingRow
                header="settings.continuous-training"
                description={
                  <Flex direction="column" gap={RADIX.FLEX.GAP.SM}>
                    <Text>
                      {t(
                        machineCx.machine.enable_continuous_training
                          ? 'settings.continuous-training-disable-msg'
                          : 'settings.continuous-training-enable-msg'
                      )}
                    </Text>
                    <CommonLink url={HELP_URLS.SETTINGS_CONTINUOUS_TRAINING}>
                      {t('common.read-more')}
                    </CommonLink>
                  </Flex>
                }
                input={
                  <Flex justify="end">
                    <CommonSwitchInput
                      id="firing-continuous-training"
                      defaultChecked={
                        machineCx.machine.enable_continuous_training
                      }
                      onCheckedChange={(v) => {
                        machineCx.update({
                          _id: machineCx.machine._id,
                          enable_continuous_training: v,
                        });
                      }}
                    />
                  </Flex>
                }
              />
            </SettingForm>
          )}
        </MachineContext.Consumer>
      </ErrorBoundary>
    );
  }
}
