import {
  EnvelopeClosedIcon,
  EnvelopeOpenIcon,
  ExclamationTriangleIcon,
  ExitIcon,
  MixerHorizontalIcon,
  QuestionMarkCircledIcon,
} from '@radix-ui/react-icons';
import { HELP_URLS } from 'classes/helpers/url.helper';
import { ErrorBoundary } from 'components/common/error-boundary';
import { CommonNavMenu } from 'components/common/layout/nav-menu';
import {
  SettingsDialog,
  SettingsSection,
} from 'components/common/settings-dialog';
import { SidebarConnectionTrigger } from 'components/main/sidebar/footer/connection-trigger';
import { SidebarNavigationTrigger } from 'components/main/sidebar/nav-trigger';
import { IAuthContext } from 'contexts/auth.context';
import { ICookiesContext } from 'contexts/cookies.context';
import { IInboxContext } from 'contexts/inbox';
import { IMachineContext, MachineDialogMode } from 'contexts/machine.context';
import {
  ISessionEventsContext,
  SessionEventsContext,
} from 'contexts/session-events.context';
import { SidebarState } from 'enums/cookies.enums';
import { SessionDialogMode } from 'enums/session.enums';
import { t } from 'i18next';
import { RADIX } from 'lib_ts/enums/radix-ui';
import { SpecialMsPosition } from 'lib_ts/interfaces/machine-msg/i-special-mstarget';
import React from 'react';
import { IntercomContextValues, useIntercom } from 'react-use-intercom';

const COMPONENT_NAME = 'SidebarFooter';

interface IBaseProps {
  authCx: IAuthContext;
  cookiesCx: ICookiesContext;
  machineCx: IMachineContext;
  inboxCx: IInboxContext;
  sessionsCx: ISessionEventsContext;
}

interface IProps extends IBaseProps {
  intercom: IntercomContextValues;
}

interface IState {}

// declared as functional to support useIntercom
export const SidebarFooter = (props: IBaseProps) => {
  const intercom = useIntercom();
  return <Helper {...props} intercom={intercom} />;
};

const SupportMenu = (props: {
  intercom: IntercomContextValues;
  authCx: IAuthContext;
  showLabels: boolean;
}) => (
  <SessionEventsContext.Consumer>
    {(sessionsCx) => (
      <SidebarNavigationTrigger
        icon={<QuestionMarkCircledIcon />}
        label="main.support"
        showLabels={props.showLabels}
        actions={[
          {
            label: 'main.current-session',
            onClick: () => {
              sessionsCx.showDialog({
                session: props.authCx.current.session,
                mode: SessionDialogMode.edit,
              });
            },
          },
          {
            label: 'main.help-center',
            onClick: () => window.open(HELP_URLS.INTERCOM_LANDING),
          },
          {
            label: 'common.contact-support',
            onClick: () => props.intercom.showNewMessage(),
          },
          {
            label: 'main.terms-of-service',
            onClick: () => props.authCx.showTermsOfService(),
          },
        ]}
      />
    )}
  </SessionEventsContext.Consumer>
);

const Logout = (props: {
  authCx: IAuthContext;
  machineCx: IMachineContext;
  showLabels: boolean;
}) => (
  <SidebarNavigationTrigger
    icon={<ExitIcon />}
    label="main.logout"
    showLabels={props.showLabels}
    onClick={() => {
      props.machineCx.specialMstarget(SpecialMsPosition.home);
      props.authCx.logout();
    }}
  />
);

const SidebarInbox = (props: {
  inboxCx: IInboxContext;
  showLabels: boolean;
}) => {
  if (props.inboxCx.inbox.length === 0) {
    return <></>;
  }

  return (
    <SidebarNavigationTrigger
      icon={
        props.inboxCx.inbox.length === 0 ? (
          <EnvelopeClosedIcon />
        ) : (
          <EnvelopeOpenIcon />
        )
      }
      label={t('main.inbox-x', { x: props.inboxCx.inbox.length }).toString()}
      showLabels={props.showLabels}
      onClick={props.inboxCx.showDialog}
    />
  );
};

const LiveView = (props: {
  machineCx: IMachineContext;
  showLabels: boolean;
}) => {
  const count = props.machineCx.lastBallCount;

  if (count === undefined) {
    return <></>;
  }

  if (count === 1) {
    return <></>;
  }

  return (
    <SidebarNavigationTrigger
      icon={<ExclamationTriangleIcon />}
      color={RADIX.COLOR.WARNING}
      label="main.inspect"
      showLabels={props.showLabels}
      onClick={() => props.machineCx.setDialog(MachineDialogMode.Inspect)}
    />
  );
};

const Settings = (props: {
  machineCx: IMachineContext;
  sessionsCx: ISessionEventsContext;
  showLabels: boolean;
}) => {
  if (!props.machineCx.machine._id) {
    return <></>;
  }

  return (
    <SidebarNavigationTrigger
      data-testid="SettingsButton"
      icon={<MixerHorizontalIcon />}
      label="main.settings"
      showLabels={props.showLabels}
      onClick={() =>
        props.sessionsCx.setSettingsDialog({
          initialSection: SettingsSection.Machine,
        })
      }
    />
  );
};

class Helper extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {};
  }

  render() {
    const showLabels =
      this.props.cookiesCx.app.sidebar_state === SidebarState.full;

    return (
      <ErrorBoundary componentName={COMPONENT_NAME}>
        <CommonNavMenu orientation="vertical">
          <SidebarInbox inboxCx={this.props.inboxCx} showLabels={showLabels} />

          <Settings
            machineCx={this.props.machineCx}
            sessionsCx={this.props.sessionsCx}
            showLabels={showLabels}
          />

          <LiveView machineCx={this.props.machineCx} showLabels={showLabels} />

          <SidebarConnectionTrigger
            authCx={this.props.authCx}
            machineCx={this.props.machineCx}
            showLabels={showLabels}
          />

          <SupportMenu
            intercom={this.props.intercom}
            authCx={this.props.authCx}
            showLabels={showLabels}
          />

          <Logout
            authCx={this.props.authCx}
            machineCx={this.props.machineCx}
            showLabels={showLabels}
          />
        </CommonNavMenu>

        {this.props.sessionsCx.settingsDialog && (
          <SettingsDialog
            {...this.props.sessionsCx.settingsDialog}
            identifier="SidebarFooterSettingsDialog"
            authCx={this.props.authCx}
            cookiesCx={this.props.cookiesCx}
            machineCx={this.props.machineCx}
            onClose={() => this.props.sessionsCx.setSettingsDialog()}
          />
        )}
      </ErrorBoundary>
    );
  }
}
