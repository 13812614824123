import {
  JwtType,
  LanguageCode,
  UserRole,
  UserStatus,
} from '../enums/auth.enums';
import { MlbAbbreviation } from '../enums/batrack.enums';
import { League } from '../enums/team.enums';
import { ICombinedPermissions } from './i-permissions';
import { IUserSettings } from './i-user';

/**
 * basic: default
 * impostor: admin is viewing the app as if logged in as another user
 * super: admin is viewing the app with access to data from all teams/users
 */
export enum SessionMode {
  Basic = 'basic',
  Impostor = 'impostor',
  Super = 'super',
}

export interface IAuthToken
  extends ICombinedPermissions,
    Partial<IUserSettings> {
  version: string;
  email: string;
  expiresIn: number;

  machineID: string;
  machineNickname?: string;

  role: UserRole;
  session: string;
  status: UserStatus;

  teamID: string;
  team: string;
  abbreviation?: MlbAbbreviation;

  league: League;
  type: JwtType;
  userID: string;
  mode: SessionMode;

  language?: LanguageCode;
}

export interface IUserCRUD extends IAuthToken {
  password: string;
  token: string;
}
