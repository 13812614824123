import { MinusIcon, PlusIcon } from '@radix-ui/react-icons';
import {
  Box,
  Button,
  Flex,
  Grid,
  Heading,
  IconButton,
  Separator,
  Spinner,
} from '@radix-ui/themes';
import { NotifyHelper } from 'classes/helpers/notify.helper';
import { PitchDesignHelper } from 'classes/helpers/pitch-design.helper';
import { HELP_URLS } from 'classes/helpers/url.helper';
import { CopyPitchesDialog } from 'components/common/dialogs/copy-pitches';
import { ErrorBoundary } from 'components/common/error-boundary';
import { CommonTextInput } from 'components/common/form/text';
import { CommonContentWithSidebar } from 'components/common/layout/content-with-sidebar';
import { MachineCalibrateButton } from 'components/machine/buttons/calibrate';
import { PresetTrainingDialog } from 'components/machine/dialogs/preset-training';
import { TrainingDialog } from 'components/machine/dialogs/training';
import { SectionHeader } from 'components/sections/header';
import { BallFlightDesigner } from 'components/sections/pitch-design/ball-flight-designer';
import { PitchDesignSidebar } from 'components/sections/pitch-design/sidebar';
import { IAuthContext } from 'contexts/auth.context';
import { ICookiesContext } from 'contexts/cookies.context';
import { IMachineContext } from 'contexts/machine.context';
import { IMatchingShotsContext } from 'contexts/pitch-lists/matching-shots.context';
import { PitchListsContext } from 'contexts/pitch-lists/pitch-lists.context';
import { IPitchContext } from 'contexts/pitch-lists/pitch.context';
import { DirtyForm, ISectionsContext } from 'contexts/sections.context';
import { TrainingContext, TrainingProvider } from 'contexts/training.context';
import { VideosContext } from 'contexts/videos/videos.context';
import { CookieKey } from 'enums/cookies.enums';
import { t } from 'i18next';
import { AimingHelper } from 'lib_ts/classes/aiming.helper';
import { BallHelper } from 'lib_ts/classes/ball.helper';
import {
  getMSFromMSDict,
  getMachineActiveModelID,
  getMergedMSDict,
} from 'lib_ts/classes/ms.helper';
import { TrajHelper } from 'lib_ts/classes/trajectory.helper';
import { ERROR_MSGS } from 'lib_ts/enums/errors.enums';
import { TrainingMode } from 'lib_ts/enums/machine.enums';
import {
  BuildPriority,
  ORIENTATION_SEAM_ALT_AZ,
  Orientation,
} from 'lib_ts/enums/pitches.enums';
import { RADIX } from 'lib_ts/enums/radix-ui';
import { IBallChar } from 'lib_ts/interfaces/i-ball-char';
import {
  DEFAULT_BALL_STATE,
  DEFAULT_PLATE,
  IBuildPitchChars,
  IPitch,
} from 'lib_ts/interfaces/pitches';
import { ISpin, ISpinExt } from 'lib_ts/interfaces/pitches/i-base';
import React from 'react';
import Slider from 'react-input-slider';
import { PitchListsService } from 'services/pitch-lists.service';
import { PitchesService } from 'services/pitches.service';
import { SessionEventsService } from 'services/session-events.service';
import { StateTransformService } from 'services/state-transform.service';
import { v4 } from 'uuid';

const COMPONENT_NAME = 'PitchDesign';

const LIMIT_LATITUDE_DEG = 90;
const LIMIT_LONGITUDE_DEG = 180;

const PITCH_BUILDING_WARNING_MSG = `Please wait for your pitch to finish building before trying again.`;

const REBUILD_ON_PLATE_CHANGE = false;

interface IProps {
  cookiesCx: ICookiesContext;
  authCx: IAuthContext;
  machineCx: IMachineContext;
  matchingCx: IMatchingShotsContext;
  pitchCx: IPitchContext;
  sectionsCx: ISectionsContext;
}

interface IBreaksTextInputs {
  speed: string;
  wy: string;
  breaksX: string;
  breaksZ: string;
}

interface ISpinsTextInputs {
  speed: string;
  wnet: string;
  gyro_angle: string;
  waxis: string;
}

interface IDefaultTextInputs {
  speed: string;
  wx: string;
  wy: string;
  wz: string;
}

interface IDialogs {
  dialogSave?: number;
  dialogTraining?: number;
}

interface IState
  extends Partial<IBreaksTextInputs>,
    Partial<ISpinsTextInputs>,
    Partial<IDefaultTextInputs>,
    IDialogs {
  ball: IBallChar;

  /** based on activePitch to start (if possible), synced with bs derived from ball
   * necessary for plate view */
  pitchChars?: Partial<IBuildPitchChars>;

  /** taken from pitch context > active at init, used for updating instead of creating new pitches */
  refPitch?: IPitch;

  selectedPitches: Partial<IPitch>[];

  // to handle update ball after timeout on slider drag end
  temp_latitude_deg: number;
  // to handle update ball after timeout on slider drag end
  temp_longitude_deg: number;
}

const getOrientationOptions = () => {
  return [
    { label: t('pd.two-seam'), value: Orientation.FF2 },
    { label: t('pd.four-seam'), value: Orientation.FF4 },
    { label: t('pd.custom-orientation'), value: Orientation.CUS },
  ];
};

const getSpinTooltipMD = (spin: 'x' | 'y' | 'z'): string => {
  switch (spin) {
    case 'x': {
      return [
        t('pd.rpm-from-pitcher-pov').toString(),
        `**${t('pd.positive')}**: ${t('pd.top-spin')}`,
        `**${t('pd.negative')}**: ${t('pd.back-spin')}`,
      ].join('\n\n');
    }

    case 'y': {
      return [
        t('pd.rpm-from-pitcher-pov').toString(),
        `**${t('pd.positive')}**: ${t('pd.counter-clockwise')}`,
        `**${t('pd.negative')}**: ${t('pd.clockwise')}`,
      ].join('\n\n');
    }

    case 'z': {
      return [
        t('pd.rpm-from-pitcher-pov').toString(),
        `**${t('pd.positive')}**: ${t('pd.spin-left')}`,
        `**${t('pd.negative')}**: ${t('pd.spin-right')}`,
      ].join('\n\n');
    }

    default: {
      return '';
    }
  }
};

export class PitchDesign extends React.Component<IProps, IState> {
  private init = false;
  private validInputsTimeout: any;
  private sidebar?: PitchDesignSidebar;
  private buildQueued = false;

  constructor(props: IProps) {
    super(props);

    const aPitch = props.pitchCx.active;

    const initialBall = (() => {
      if (aPitch) {
        /** e.g. editing a pitch from a list */
        if (aPitch.priority) {
          // make sure the editor reflects whatever the pitch prioritized
          props.cookiesCx.setCookie(CookieKey.app, {
            build_priority: aPitch.priority,
          });
        }
        return BallHelper.getCharsFromPitch(aPitch);
      }

      /** default (e.g. from sidebar) */
      const defSpin: ISpin = {
        wx: -2000,
        wy: 0,
        wz: 0,
      };

      const defSpinExt = BallHelper.convertSpinToSpinExt(defSpin);

      const defBall: IBallChar = {
        speed: 75,

        wx: defSpin.wx,
        wy: defSpin.wy,
        wz: defSpin.wz,

        wnet: defSpinExt.wnet,
        gyro_angle: defSpinExt.gyro_angle,
        waxis: defSpinExt.waxis,

        breaks: {
          xInches: 0,
          zInches: 18,
        },

        orientation: Orientation.FF4,
        latitude_deg: 0,
        longitude_deg: 0,

        px: DEFAULT_BALL_STATE.px,
        py: props.machineCx.machine.plate_distance,
        pz: DEFAULT_BALL_STATE.pz,
      };

      return defBall;
    })();

    const activeMs = aPitch
      ? getMSFromMSDict(aPitch, props.machineCx.machine).ms
      : undefined;

    const initialState: IState = {
      ball: initialBall,

      temp_latitude_deg: initialBall.latitude_deg,
      temp_longitude_deg: initialBall.longitude_deg,

      pitchChars:
        aPitch && activeMs
          ? {
              bs: aPitch.bs,
              ms: activeMs,
              traj: aPitch.traj,
              plate: TrajHelper.getPlateLoc(aPitch.traj),
              seams: aPitch.seams,
              breaks: aPitch.breaks,
            }
          : undefined,

      selectedPitches: [],

      // common to all modes
      speed: initialBall.speed.toFixed(1),

      // breaks prio
      wy: initialBall.wy.toFixed(0),
      breaksX: (-(initialBall.breaks?.xInches ?? 0)).toFixed(1),
      breaksZ: (initialBall.breaks?.zInches ?? 0).toFixed(1),

      // spins prio
      wnet: initialBall.wnet.toFixed(0),
      gyro_angle: initialBall.gyro_angle.toFixed(0),
      waxis: initialBall.waxis.toFixed(0),

      // default
      wx: initialBall.wx.toFixed(0),
      // repeated by spins prio
      // wy: initialBall.wy.toFixed(0),
      wz: initialBall.wz.toFixed(0),
    };

    this.state = {
      ...initialState,

      /** retain a copy of the pitch so that pitch context clearing the value doesn't affect this component */
      refPitch: aPitch,

      ball: initialBall,
    };

    this.canBuildPitch = this.canBuildPitch.bind(this);
    this.getBuildPayload = this.getBuildPayload.bind(this);
    this.getBuildPriority = this.getBuildPriority.bind(this);
    this.getPitchPayload = this.getPitchPayload.bind(this);
    this.handleAddPitch = this.handleAddPitch.bind(this);
    this.handleUpdatePitch = this.handleUpdatePitch.bind(this);
    this.handleTrainPitch = this.handleTrainPitch.bind(this);
    this.loadPitchChars = this.loadPitchChars.bind(this);
    this.setBall = this.setBall.bind(this);
    this.syncBallOrientation = this.syncBallOrientation.bind(this);
    this.updateRelease = this.updateRelease.bind(this);

    this.renderBody = this.renderBody.bind(this);
    this.renderSidebar = this.renderSidebar.bind(this);
    this.renderFooter = this.renderFooter.bind(this);
    this.renderForm = this.renderForm.bind(this);
    this.renderInputMode = this.renderInputMode.bind(this);
    this.renderSaveDialog = this.renderSaveDialog.bind(this);
    this.renderSeamOrientation = this.renderSeamOrientation.bind(this);
    this.renderTrainingDialog = this.renderTrainingDialog.bind(this);
  }

  componentDidMount() {
    if (this.init) {
      return;
    }

    this.init = true;

    if (!this.state.pitchChars) {
      this.loadPitchChars(10);
    }
  }

  componentDidUpdate(
    prevProps: Readonly<IProps>,
    prevState: Readonly<IState>
  ): void {
    /** keep plate distance in sync with settings */
    if (
      prevProps.machineCx.machine.plate_distance !==
      this.props.machineCx.machine.plate_distance
    ) {
      this.setState({
        ball: {
          ...this.state.ball,
          py: this.props.machineCx.machine.plate_distance,
        },
      });
      return;
    }

    const plateXChanged =
      prevState.pitchChars?.plate?.plate_x !==
      this.state.pitchChars?.plate?.plate_x;

    const plateZChanged =
      prevState.pitchChars?.plate?.plate_z !==
      this.state.pitchChars?.plate?.plate_z;

    if (REBUILD_ON_PLATE_CHANGE && (plateXChanged || plateZChanged)) {
      this.loadPitchChars(0);
      return;
    }
  }

  componentWillUnmount() {
    clearTimeout(this.validInputsTimeout);
    this.props.pitchCx.setActivePitch();
  }

  /** rebuilds pitchChars from bs (derived from ball + plate) */
  private loadPitchChars(remainingAttempts: number) {
    if (!this.canBuildPitch()) {
      if (remainingAttempts === 0) {
        return;
      }

      setTimeout(() => {
        /** try again shortly */
        this.loadPitchChars(remainingAttempts - 1);
      }, 500);
      return;
    }

    // pitch can be built
    StateTransformService.getInstance()
      .buildPitches({
        machine: this.props.machineCx.machine,
        notifyError: false,
        pitches: [this.getBuildPayload()],
      })
      .then((chars) => {
        if (chars && chars.length > 0) {
          this.setState({ pitchChars: chars[0] });
          return;
        }

        if (remainingAttempts > 0) {
          /** try again after a brief pause... */
          setTimeout(() => {
            this.loadPitchChars(remainingAttempts - 1);
          }, 2_000);
          return;
        }

        /** notify the user about the error */
        NotifyHelper.warning({
          message_md: `There was a problem building your pitch. ${ERROR_MSGS.CONTACT_SUPPORT}`,
          buttons: [
            {
              label: 'Help Center',
              onClick: () => window.open(HELP_URLS.INTERCOM_LANDING),
            },
          ],
          inbox: true,
        });
      });
  }

  /** also tells sectionsCx of unsaved changes */
  private setBall(value: Partial<IBallChar>) {
    const ball: IBallChar = {
      ...this.state.ball,
      ...value,
    };

    // update the state immediately so the input shows the change
    this.setState({
      ball: ball,
    });

    this.props.sectionsCx.markDirtyForm(DirtyForm.PitchDesign);

    this.buildQueued = true;

    // we only want a single error check + rebuild based on the most recent value, after a brief pause
    clearTimeout(this.validInputsTimeout);

    this.validInputsTimeout = setTimeout(async () => {
      const warnings = PitchDesignHelper.getBallErrors(
        this.state.ball,
        this.props.cookiesCx.app.build_priority
      );

      // show an error toast if necessary
      if (warnings.length > 0) {
        this.buildQueued = false;

        NotifyHelper.warning({
          message_md: warnings[0],
        });
        return;
      }

      // rebuild the ball (e.g. so that traj view updates)
      const chars = await StateTransformService.getInstance()
        .buildPitches({
          machine: this.props.machineCx.machine,
          notifyError: true,
          pitches: [this.getBuildPayload()],
        })
        .then((results) => results[0])
        .catch((buildErr) => {
          console.error(buildErr);
        })
        .finally(() => {
          this.buildQueued = false;
        });

      if (!chars) {
        NotifyHelper.warning({
          message_md: 'Empty results received from pitch build.',
        });
        return;
      }

      const nextBall = { ...this.state.ball };

      switch (this.props.cookiesCx.app.build_priority) {
        case BuildPriority.Breaks: {
          if (chars.bs) {
            nextBall.wx = chars.bs.wx;
            nextBall.wy = chars.bs.wy;
            nextBall.wz = chars.bs.wz;

            const spinExt = BallHelper.convertSpinToSpinExt(nextBall);
            nextBall.wnet = spinExt.wnet;
            nextBall.gyro_angle = spinExt.gyro_angle;
            nextBall.waxis = spinExt.waxis;
          }
          break;
        }

        case BuildPriority.Spins:
        default: {
          if (chars.breaks) {
            nextBall.breaks = chars.breaks;
          }
          break;
        }
      }

      this.setState(
        {
          pitchChars: chars,
          ball: nextBall,
        },
        () => this.sidebar?.restartAnimation('rebuild ball')
      );
    }, 1_000);
  }

  // for changing release position, does not trigger a rebuild via python
  private updateRelease(value: { px: number; pz: number }) {
    const ball: IBallChar = {
      ...this.state.ball,
      px: value.px,
      pz: value.pz,
    };

    this.props.sectionsCx.markDirtyForm(DirtyForm.PitchDesign);

    this.setState(
      {
        ball: ball,
      },
      () => {
        const warnings = PitchDesignHelper.getBallErrors(
          this.state.ball,
          this.props.cookiesCx.app.build_priority
        );

        // show an error toast if necessary
        if (warnings.length > 0) {
          NotifyHelper.warning({
            message_md: warnings[0],
          });
          return;
        }

        // merge the new value with existing value without triggering rebuild (e.g. for release position change)
        const chars: Partial<IBuildPitchChars> = {
          ...this.state.pitchChars,
        };

        if (chars.bs && chars.traj && chars.ms && chars.plate) {
          chars.bs.px = value.px;
          chars.bs.pz = value.pz;

          chars.traj.px = value.px;
          chars.traj.pz = value.pz;

          const aimed = AimingHelper.aimWithoutShots({
            chars: {
              bs: chars.bs,
              ms: chars.ms,
              traj: chars.traj,

              seams: chars.seams,
              breaks: chars.breaks,
              priority: this.getBuildPriority(),
            },
            release: {
              px: chars.bs.px,
              pz: chars.bs.pz,
            },
            plate_location: chars.plate,
          });

          this.setState(
            {
              pitchChars: aimed,
            },
            () => this.sidebar?.restartAnimation('skip rebuild ball')
          );
        }
      }
    );
  }

  /** checks for errors, notifies if any, returns true iff there are no errors */
  private canBuildPitch(): boolean {
    if (!this.props.authCx.current.auth) {
      return false;
    }

    if (!getMachineActiveModelID(this.props.machineCx.machine)) {
      return false;
    }

    const warnings = PitchDesignHelper.getBallErrors(
      this.state.ball,
      this.props.cookiesCx.app.build_priority
    );
    warnings.forEach((text) => console.warn(text));

    return warnings.length === 0;
  }

  private getBuildPriority(): BuildPriority {
    return this.props.cookiesCx.app.build_priority ?? BuildPriority.Spins;
  }

  private async handleTrainPitch() {
    if (this.buildQueued) {
      NotifyHelper.warning({
        message_md: PITCH_BUILDING_WARNING_MSG,
      });
      return;
    }

    const payload = this.getPitchPayload();
    if (!payload) {
      return;
    }

    this.setState({
      dialogTraining: Date.now(),
      selectedPitches: [payload],
    });
  }

  private getBuildPayload(): Partial<IBuildPitchChars> {
    const prio = this.getBuildPriority();

    return {
      temp_index: 0,
      bs: BallHelper.getBallStateFromChars(this.state.ball),
      plate: this.state.pitchChars?.plate ?? DEFAULT_PLATE,
      priority: prio,
      seams: {
        latitude_deg: this.state.ball.latitude_deg,
        longitude_deg: this.state.ball.longitude_deg,
      },
      breaks:
        prio === BuildPriority.Breaks ? this.state.ball.breaks : undefined,
    };
  }

  private getPitchPayload(): Partial<IPitch> | undefined {
    const chars = this.state.pitchChars;

    if (!chars) {
      NotifyHelper.debug(
        {
          message_md: 'No pitch chars specified!',
        },
        this.props.cookiesCx
      );
      return;
    }

    if (!chars.bs) {
      NotifyHelper.debug(
        {
          message_md: 'No ball state specified!',
        },
        this.props.cookiesCx
      );
      return;
    }

    if (!chars.ms) {
      NotifyHelper.debug(
        {
          message_md: 'No machine state specified!',
        },
        this.props.cookiesCx
      );
      return;
    }

    if (!chars.traj) {
      NotifyHelper.debug(
        {
          message_md: 'No trajectory specified!',
        },
        this.props.cookiesCx
      );
      return;
    }

    if (!chars.plate) {
      NotifyHelper.debug(
        {
          message_md: 'No plate specified!',
        },
        this.props.cookiesCx
      );
      return;
    }

    const prio = this.getBuildPriority();

    if (prio === BuildPriority.Breaks && !this.state.ball.breaks) {
      NotifyHelper.debug(
        {
          message_md: 'No breaks specified while prioritizing breaks!',
        },
        this.props.cookiesCx
      );
      return;
    }

    const aimed = AimingHelper.aimWithoutShots({
      chars: {
        bs: chars.bs,
        ms: chars.ms,
        traj: chars.traj,

        seams: chars.seams,
        // breaks are not necessary for aiming
        // breaks: this.state.ball.breaks,
        priority: prio,
      },
      release: {
        px: chars.bs.px,
        pz: chars.bs.pz,
      },
      plate_location: chars.plate,
    });

    const output: Partial<IPitch> = {
      _id: this.state.refPitch?._id ?? `temp-${v4()}`,
      name: this.state.refPitch?.name ?? '',

      bs: aimed.bs,
      msDict: getMergedMSDict(this.props.machineCx.machine, [aimed.ms]),
      traj: aimed.traj,

      seams: chars.seams,
      priority: prio,
      breaks:
        prio === BuildPriority.Breaks ? this.state.ball.breaks : undefined,

      plate_loc_backup: aimed.plate,
    };

    /** append video if selected, else leave alone to avoid overwriting video with undefined */
    const videoID = this.sidebar?.getVideoID();

    if (videoID) {
      output.video_id = videoID;
    }

    return output;
  }

  private async handleAddPitch() {
    if (this.buildQueued) {
      NotifyHelper.warning({
        message_md: PITCH_BUILDING_WARNING_MSG,
      });
      return;
    }

    const payload = this.getPitchPayload();
    if (!payload) {
      return;
    }

    this.setState({
      dialogSave: Date.now(),
      selectedPitches: [payload],
    });
  }

  private async handleUpdatePitch() {
    if (!this.state.refPitch) {
      return;
    }

    if (this.buildQueued) {
      NotifyHelper.warning({
        message_md: PITCH_BUILDING_WARNING_MSG,
      });
      return;
    }

    const payload = this.getPitchPayload();
    if (!payload) {
      return;
    }

    // add a new pitch that looks like the existing one
    const result = await PitchListsService.getInstance().postPitchesToList({
      listID: this.state.refPitch._parent_id,
      data: [
        {
          // take everything from the original pitch
          ...this.state.refPitch,
          // override specific fields with payload results
          ...payload,
        },
      ],
    });

    if (!result.success) {
      NotifyHelper.warning({
        message_md: result.error ?? t('pd.error-building-pitch-for-updating'),
      });
      return;
    }

    /** clear warning */
    this.props.sectionsCx.clearDirtyForm(DirtyForm.PitchDesign);

    // mark the old pitch as deleted
    await PitchesService.getInstance().deletePitches([this.state.refPitch._id]);

    NotifyHelper.success({
      message_md: t('common.x-updated', { x: this.state.refPitch.name }),
    });
  }

  private syncBallOrientation() {
    if (
      this.state.temp_latitude_deg === this.state.ball.latitude_deg &&
      this.state.temp_longitude_deg === this.state.ball.longitude_deg
    ) {
      return;
    }

    this.setBall({
      latitude_deg: this.state.temp_latitude_deg,
      longitude_deg: this.state.temp_longitude_deg,
    });
  }

  private renderTrainingDialog() {
    if (!this.state.dialogTraining) {
      return;
    }

    if (!this.state.selectedPitches) {
      return;
    }

    if (this.state.selectedPitches.length === 0) {
      return;
    }

    const mode = this.props.authCx.effectiveTrainingMode();

    if (mode === TrainingMode.Manual) {
      return (
        <TrainingProvider cookiesCx={this.props.cookiesCx} mode={mode}>
          <TrainingContext.Consumer>
            {(trainingCx) => (
              <TrainingDialog
                key={this.state.dialogTraining}
                identifier="PD-TrainingDialog"
                machineCx={this.props.machineCx}
                trainingCx={trainingCx}
                pitches={this.state.selectedPitches}
                threshold={this.props.machineCx.machine.training_threshold}
                onClose={() => this.setState({ dialogTraining: undefined })}
              />
            )}
          </TrainingContext.Consumer>
        </TrainingProvider>
      );
    }

    return (
      <TrainingProvider cookiesCx={this.props.cookiesCx} mode={mode}>
        <TrainingContext.Consumer>
          {(trainingCx) => (
            <PresetTrainingDialog
              key={this.state.dialogTraining}
              identifier="PD-PT-TrainingDialog"
              machineCx={this.props.machineCx}
              trainingCx={trainingCx}
              pitches={this.state.selectedPitches}
              onClose={() => this.setState({ dialogTraining: undefined })}
            />
          )}
        </TrainingContext.Consumer>
      </TrainingProvider>
    );
  }

  private renderSaveDialog() {
    if (!this.state.dialogSave) {
      return;
    }

    return (
      <PitchListsContext.Consumer>
        {(listsCx) => (
          <CopyPitchesDialog
            key={this.state.dialogSave}
            identifier="PitchDesignSavePitchDialog"
            authCx={this.props.authCx}
            listsCx={listsCx}
            pitches={this.state.selectedPitches}
            description={t('pd.msg-new-pitch-ready').toString()}
            onCreated={() => {
              this.props.sectionsCx.clearDirtyForm(DirtyForm.PitchDesign);

              SessionEventsService.postEvent({
                category: 'pitch',
                tags: 'designer',
                data: {
                  action: 'save',
                  count: 1,
                },
              });

              this.setState({ dialogSave: undefined });
            }}
            onClose={() => this.setState({ dialogSave: undefined })}
          />
        )}
      </PitchListsContext.Consumer>
    );
  }

  render() {
    const refPitch = this.state.refPitch;

    return (
      <ErrorBoundary componentName="PitchDesign">
        <Flex direction="column" gap={RADIX.FLEX.GAP.SECTION}>
          <SectionHeader
            header={t('main.pitch-design')}
            badge={refPitch?.name}
          />

          <CommonContentWithSidebar
            left={this.renderBody()}
            right={this.renderSidebar()}
          />
        </Flex>

        {this.renderSaveDialog()}
        {this.renderTrainingDialog()}
      </ErrorBoundary>
    );
  }

  private renderSidebar() {
    return (
      <VideosContext.Consumer>
        {(videosCx) => (
          <PitchDesignSidebar
            ref={(elem) => (this.sidebar = elem as PitchDesignSidebar)}
            videosCx={videosCx}
            pitch={this.props.pitchCx.active}
            ball={this.state.ball}
            chars={this.state.pitchChars}
          />
        )}
      </VideosContext.Consumer>
    );
  }

  private renderBody() {
    return (
      <Flex direction="column" gap={RADIX.FLEX.GAP.MD}>
        <Heading size={RADIX.HEADING.SIZE.MD}>
          {t('pd.ball-release-characteristics')}
        </Heading>

        {this.renderInputMode()}

        {this.renderForm()}

        <Separator size="4" />

        {this.renderSeamOrientation()}

        <Separator size="4" />

        <Heading size={RADIX.HEADING.SIZE.MD}>
          {t('pd.ball-flight-characteristics')}
        </Heading>

        {this.state.pitchChars && (
          <BallFlightDesigner
            cookiesCx={this.props.cookiesCx}
            authCx={this.props.authCx}
            machineCx={this.props.machineCx}
            chars={this.state.pitchChars}
            onUpdatePlate={(plate) => {
              if (!this.state.pitchChars?.bs) {
                return;
              }

              if (!this.state.pitchChars?.traj) {
                return;
              }

              if (!this.state.pitchChars?.ms) {
                return;
              }

              const rotatedChars = AimingHelper.aimWithoutShots({
                chars: {
                  bs: this.state.pitchChars.bs,
                  traj: this.state.pitchChars.traj,
                  ms: this.state.pitchChars.ms,
                  priority:
                    this.state.pitchChars.priority ?? BuildPriority.Spins,
                },
                release: {
                  px: this.state.ball.px,
                  pz: this.state.ball.pz,
                },
                plate_location: plate,
              });

              this.setState(
                {
                  pitchChars: rotatedChars,
                },
                () =>
                  this.sidebar?.restartAnimation(
                    `${COMPONENT_NAME} > update plate`
                  )
              );
            }}
            onUpdateRelease={(pos) =>
              this.updateRelease({ px: pos.px, pz: pos.pz })
            }
          />
        )}

        {!this.state.pitchChars && <Spinner />}

        {this.renderFooter()}
      </Flex>
    );
  }

  private renderForm() {
    return (
      <Grid columns="4" gap={RADIX.FLEX.GAP.SM}>
        <Box>
          <CommonTextInput
            id="pitch-design-speed"
            label={t('pd.speed-units', { units: 'mph' }).toString()}
            inputColor={
              PitchDesignHelper.validateBallSpeed(this.state.ball.speed)
                ? undefined
                : RADIX.COLOR.WARNING
            }
            type="number"
            value={this.state.speed}
            onChange={(v) => {
              this.setState({ speed: v });
            }}
            onNumericChange={(v) => {
              this.setBall({
                speed: v,
              });
            }}
          />
        </Box>

        {!this.props.cookiesCx.app.build_priority && (
          <>
            <Box>
              <CommonTextInput
                id="pitch-design-spin-x"
                label="pd.spin-x"
                iconTooltip={getSpinTooltipMD('x')}
                inputColor={
                  PitchDesignHelper.validateSpin(this.state.ball.wx)
                    ? undefined
                    : RADIX.COLOR.WARNING
                }
                type="number"
                value={this.state.wx}
                onChange={(v) => {
                  this.setState({ wx: v });
                }}
                onNumericChange={(e) => {
                  const nextSpin: ISpin = {
                    ...this.state.ball,
                    wx: e,
                  };

                  const nextSpinExt = PitchDesignHelper.validateSpin(e)
                    ? BallHelper.convertSpinToSpinExt(nextSpin)
                    : undefined;

                  this.setBall({
                    ...nextSpinExt,
                    wx: e,
                  });
                }}
              />
            </Box>
            <Box>
              <CommonTextInput
                id="pitch-design-spin-y"
                label="pd.spin-y"
                type="number"
                iconTooltip={getSpinTooltipMD('y')}
                inputColor={
                  PitchDesignHelper.validateSpin(this.state.ball.wy)
                    ? undefined
                    : RADIX.COLOR.WARNING
                }
                value={this.state.wy}
                onChange={(v) => {
                  this.setState({ wy: v });
                }}
                onNumericChange={(v) => {
                  const nextSpin: ISpin = {
                    ...this.state.ball,
                    wy: v,
                  };

                  const nextSpinExt = PitchDesignHelper.validateSpin(v)
                    ? BallHelper.convertSpinToSpinExt(nextSpin)
                    : undefined;

                  this.setBall({
                    ...nextSpinExt,
                    wy: v,
                  });
                }}
              />
            </Box>
            <Box>
              <CommonTextInput
                id="pitch-design-spin-z"
                type="number"
                label="pd.spin-z"
                iconTooltip={getSpinTooltipMD('z')}
                inputColor={
                  PitchDesignHelper.validateSpin(this.state.ball.wz)
                    ? undefined
                    : RADIX.COLOR.WARNING
                }
                value={this.state.wz}
                onChange={(v) => {
                  this.setState({ wz: v });
                }}
                onNumericChange={(v) => {
                  const nextSpin: ISpin = {
                    ...this.state.ball,
                    wz: v,
                  };

                  const nextSpinExt = PitchDesignHelper.validateSpin(v)
                    ? BallHelper.convertSpinToSpinExt(nextSpin)
                    : undefined;

                  this.setBall({
                    ...nextSpinExt,
                    wz: v,
                  });
                }}
              />
            </Box>
          </>
        )}

        {this.props.cookiesCx.app.build_priority === BuildPriority.Spins && (
          <>
            <Box>
              <CommonTextInput
                id="pitch-design-spin-net"
                label="Net Spin"
                inputColor={
                  PitchDesignHelper.validateSpin(this.state.ball.wnet)
                    ? undefined
                    : RADIX.COLOR.WARNING
                }
                type="number"
                value={this.state.wnet}
                onChange={(v) => {
                  this.setState({ wnet: v });
                }}
                onNumericChange={(v) => {
                  // NET SPIN - NET MODE
                  const nextSpinExt: ISpinExt = {
                    ...this.state.ball,
                    wnet: v,
                  };

                  // don't update spin if the value isn't valid
                  const nextSpin = PitchDesignHelper.validateSpin(v)
                    ? BallHelper.convertSpinExtToSpin(nextSpinExt)
                    : undefined;

                  this.setBall({
                    wnet: v,
                    ...nextSpin,
                  });
                }}
              />
            </Box>
            <Box>
              <CommonTextInput
                id="pitch-design-gyro"
                label="Gyro Angle (deg)"
                inputColor={
                  PitchDesignHelper.validateGyroAngle(
                    this.state.ball.gyro_angle
                  )
                    ? undefined
                    : RADIX.COLOR.WARNING
                }
                type="number"
                value={this.state.gyro_angle}
                onChange={(v) => {
                  this.setState({ gyro_angle: v });
                }}
                onNumericChange={(v) => {
                  const nextSpinExt: ISpinExt = {
                    ...this.state.ball,
                    gyro_angle: v,
                  };

                  // don't update spin if the value isn't valid
                  const nextSpin = PitchDesignHelper.validateGyroAngle(v)
                    ? BallHelper.convertSpinExtToSpin(nextSpinExt)
                    : undefined;

                  this.setBall({
                    gyro_angle: v,
                    ...nextSpin,
                  });
                }}
              />
            </Box>
            <Box>
              <CommonTextInput
                id="pitch-design-spin-axis"
                label="Spin Axis (deg)"
                inputColor={
                  PitchDesignHelper.validateSpinAxis(this.state.ball.waxis)
                    ? undefined
                    : RADIX.COLOR.WARNING
                }
                type="number"
                value={this.state.waxis}
                onChange={(v) => {
                  this.setState({ waxis: v });
                }}
                onNumericChange={(v) => {
                  const nextSpinExt: ISpinExt = {
                    ...this.state.ball,
                    waxis: v,
                  };

                  // don't update spin if the value isn't valid
                  const nextSpin = PitchDesignHelper.validateSpinAxis(v)
                    ? BallHelper.convertSpinExtToSpin(nextSpinExt)
                    : undefined;

                  this.setBall({
                    waxis: v,
                    ...nextSpin,
                  });
                }}
              />
            </Box>
          </>
        )}

        {this.props.cookiesCx.app.build_priority === BuildPriority.Breaks && (
          <>
            <Box>
              <CommonTextInput
                id="pitch-design-spin-gyro"
                label="Gyro Spin"
                inputColor={
                  PitchDesignHelper.validateSpin(this.state.ball.wnet)
                    ? undefined
                    : RADIX.COLOR.WARNING
                }
                type="number"
                value={this.state.wy}
                onChange={(v) => {
                  this.setState({ wy: v });
                }}
                onNumericChange={(v) => {
                  // NET SPIN - BREAKS MODE
                  this.setBall({
                    wy: v,
                  });
                }}
              />
            </Box>
            <Box>
              <CommonTextInput
                id="pitch-design-hor-break"
                label="Hor. Break (in)"
                iconTooltip={PitchDesignHelper.HB_TOOLTIP_TEXT}
                inputColor={
                  PitchDesignHelper.validateBreak(
                    this.state.ball.breaks?.xInches
                  )
                    ? undefined
                    : RADIX.COLOR.WARNING
                }
                type="number"
                value={this.state.breaksX}
                onChange={(v) => {
                  this.setState({ breaksX: v });
                }}
                onNumericChange={(v) => {
                  this.setBall({
                    breaks: {
                      xInches: -1 * v,
                      zInches: this.state.ball.breaks?.zInches ?? 0,
                    },
                  });
                }}
              />
            </Box>
            <Box>
              <CommonTextInput
                id="pitch-design-vert-break"
                label="Vert. Break (in)"
                iconTooltip={PitchDesignHelper.VB_TOOLTIP_TEXT}
                inputColor={
                  PitchDesignHelper.validateBreak(
                    this.state.ball.breaks?.zInches
                  )
                    ? undefined
                    : RADIX.COLOR.WARNING
                }
                type="number"
                value={this.state.breaksZ}
                onChange={(v) => {
                  this.setState({ breaksZ: v });
                }}
                onNumericChange={(e) => {
                  this.setBall({
                    breaks: {
                      zInches: e,
                      xInches: this.state.ball.breaks?.xInches ?? 0,
                    },
                  });
                }}
              />
            </Box>
          </>
        )}
      </Grid>
    );
  }

  private renderInputMode() {
    return (
      <>
        <Heading size={RADIX.HEADING.SIZE.SM}>Input Mode</Heading>

        <Grid
          columns={(Object.values(BuildPriority).length + 1).toString()}
          gap={RADIX.FLEX.GAP.SM}
        >
          <Box>
            <Button
              className="btn-block"
              color={RADIX.COLOR.NEUTRAL}
              variant={
                !this.props.cookiesCx.app.build_priority
                  ? RADIX.BUTTON.VARIANT.SELECTED
                  : RADIX.BUTTON.VARIANT.NOT_SELECTED
              }
              onClick={() => {
                this.setState({
                  speed: this.state.ball.speed.toFixed(1),
                  wx: this.state.ball.wx.toFixed(0),
                  wy: this.state.ball.wy.toFixed(0),
                  wz: this.state.ball.wz.toFixed(0),
                });

                this.props.cookiesCx.setCookie(CookieKey.app, {
                  build_priority: undefined,
                });
              }}
            >
              Default
            </Button>
          </Box>

          {Object.values(BuildPriority).map((v) => (
            <Box key={`form-mode-${v}`}>
              <Button
                className="btn-block"
                color={RADIX.COLOR.NEUTRAL}
                variant={
                  this.props.cookiesCx.app.build_priority === v
                    ? RADIX.BUTTON.VARIANT.SELECTED
                    : RADIX.BUTTON.VARIANT.NOT_SELECTED
                }
                onClick={() => {
                  switch (v) {
                    case BuildPriority.Breaks: {
                      this.setState({
                        speed: this.state.ball.speed.toFixed(1),
                        wy: this.state.ball.wy.toFixed(0),
                        breaksX: (-(
                          this.state.ball.breaks?.xInches ?? 0
                        )).toFixed(1),
                        breaksZ: (this.state.ball.breaks?.zInches ?? 0).toFixed(
                          1
                        ),
                      });
                      break;
                    }

                    case BuildPriority.Spins: {
                      this.setState({
                        speed: this.state.ball.speed.toFixed(1),
                        wnet: this.state.ball.wnet.toFixed(0),
                        gyro_angle: this.state.ball.gyro_angle.toFixed(0),
                        waxis: this.state.ball.waxis.toFixed(0),
                      });
                      break;
                    }

                    default: {
                      break;
                    }
                  }

                  this.props.cookiesCx.setCookie(CookieKey.app, {
                    build_priority: v,
                  });
                }}
              >
                {v}
              </Button>
            </Box>
          ))}
        </Grid>
      </>
    );
  }

  private renderSeamOrientation() {
    const options = getOrientationOptions();

    return (
      <>
        <Heading size={RADIX.HEADING.SIZE.SM}>
          {t('pd.seam-orientation')}
        </Heading>

        <Grid columns={options.length.toString()} gap={RADIX.FLEX.GAP.SM}>
          {options.map((o, i) => {
            const active = this.state.ball.orientation === o.value;

            return (
              <Box key={i}>
                <Button
                  className="btn-block"
                  color={RADIX.COLOR.NEUTRAL}
                  variant={
                    active
                      ? RADIX.BUTTON.VARIANT.SELECTED
                      : RADIX.BUTTON.VARIANT.NOT_SELECTED
                  }
                  onClick={() => {
                    const known = ORIENTATION_SEAM_ALT_AZ[o.value];

                    if (known) {
                      this.setState({
                        temp_latitude_deg: known.latitude_deg,
                        temp_longitude_deg: known.longitude_deg,
                      });

                      this.setBall({
                        orientation: o.value,

                        latitude_deg: known.latitude_deg,
                        longitude_deg: known.longitude_deg,
                      });
                      return;
                    }

                    this.setBall({
                      orientation: o.value,
                    });
                  }}
                >
                  {o.label}
                </Button>
              </Box>
            );
          })}
        </Grid>

        {this.state.ball.orientation === Orientation.CUS && (
          <Grid columns="2" gap={RADIX.FLEX.GAP.SM}>
            <Flex gap={RADIX.FLEX.GAP.SM} justify="center">
              <Box>
                <IconButton
                  color={RADIX.COLOR.NEUTRAL}
                  variant="soft"
                  disabled={this.state.temp_latitude_deg <= -LIMIT_LATITUDE_DEG}
                  onClick={() =>
                    this.setState(
                      {
                        temp_latitude_deg: this.state.temp_latitude_deg - 1,
                      },
                      () => this.syncBallOrientation()
                    )
                  }
                >
                  <MinusIcon />
                </IconButton>
              </Box>
              <Box flexGrow="1">
                <Button
                  color={RADIX.COLOR.NEUTRAL}
                  variant="soft"
                  className="btn-block"
                  onClick={() =>
                    this.setState(
                      {
                        temp_latitude_deg: 0,
                      },
                      () => this.syncBallOrientation()
                    )
                  }
                >
                  {t('pd.latitude')}: {this.state.ball.latitude_deg.toFixed(0)}
                </Button>
              </Box>
              <Box>
                <IconButton
                  color={RADIX.COLOR.NEUTRAL}
                  variant="soft"
                  disabled={this.state.temp_latitude_deg >= LIMIT_LATITUDE_DEG}
                  onClick={() =>
                    this.setState(
                      {
                        temp_latitude_deg: this.state.temp_latitude_deg + 1,
                      },
                      () => this.syncBallOrientation()
                    )
                  }
                >
                  <PlusIcon />
                </IconButton>
              </Box>
            </Flex>

            <Flex gap={RADIX.FLEX.GAP.SM} justify="center">
              <Box>
                <IconButton
                  color={RADIX.COLOR.NEUTRAL}
                  variant="soft"
                  disabled={
                    this.state.temp_longitude_deg <= -LIMIT_LONGITUDE_DEG
                  }
                  onClick={() =>
                    this.setState(
                      {
                        temp_longitude_deg: this.state.temp_longitude_deg - 1,
                      },
                      () => this.syncBallOrientation()
                    )
                  }
                >
                  <MinusIcon />
                </IconButton>
              </Box>
              <Box flexGrow="1">
                <Button
                  color={RADIX.COLOR.NEUTRAL}
                  variant="soft"
                  className="btn-block"
                  onClick={() =>
                    this.setState(
                      {
                        temp_longitude_deg: 0,
                      },
                      () => this.syncBallOrientation()
                    )
                  }
                >
                  {t('pd.longitude')}:{' '}
                  {this.state.ball.longitude_deg.toFixed(0)}
                </Button>
              </Box>
              <Box>
                <IconButton
                  color={RADIX.COLOR.NEUTRAL}
                  variant="soft"
                  disabled={
                    this.state.temp_longitude_deg >= LIMIT_LONGITUDE_DEG
                  }
                  onClick={() =>
                    this.setState(
                      {
                        temp_longitude_deg: this.state.temp_longitude_deg + 1,
                      },
                      () => this.syncBallOrientation()
                    )
                  }
                >
                  <PlusIcon />
                </IconButton>
              </Box>
            </Flex>

            <Box pl="2" pr="2">
              <Slider
                axis="x"
                xstep={1}
                xmin={-LIMIT_LATITUDE_DEG}
                xmax={LIMIT_LATITUDE_DEG}
                x={this.state.temp_latitude_deg}
                styles={{
                  active: {
                    backgroundColor: '#555',
                  },
                  track: { width: '100%' },
                }}
                onChange={(e) =>
                  this.setState({
                    temp_latitude_deg: e.x,
                  })
                }
                onDragEnd={() => {
                  setTimeout(() => this.syncBallOrientation(), 100);
                }}
              />
            </Box>
            <Box pl="2" pr="2">
              <Slider
                axis="x"
                xstep={1}
                xmin={-LIMIT_LONGITUDE_DEG}
                xmax={LIMIT_LONGITUDE_DEG}
                x={this.state.temp_longitude_deg}
                styles={{
                  active: {
                    backgroundColor: '#555',
                  },
                  track: { width: '100%' },
                }}
                onChange={(e) =>
                  this.setState({
                    temp_longitude_deg: e.x,
                  })
                }
                onDragEnd={() => {
                  setTimeout(() => this.syncBallOrientation(), 100);
                }}
              />
            </Box>
          </Grid>
        )}
      </>
    );
  }

  private renderFooter() {
    const BTN_CLASS = 'text-titlecase';
    const pitch = this.state.refPitch;

    return (
      <Flex gap={RADIX.FLEX.GAP.SM} justify="end">
        {this.props.machineCx.connected && (
          <Box>
            {this.props.machineCx.calibrated ? (
              <Button
                className={BTN_CLASS}
                color={RADIX.COLOR.TRAIN_PITCH}
                disabled={!this.props.matchingCx.readyToTrain()}
                onClick={() => this.handleTrainPitch()}
              >
                {t('common.train-pitch')}
              </Button>
            ) : (
              <MachineCalibrateButton className={BTN_CLASS} />
            )}
          </Box>
        )}

        {pitch?._id && (
          <Box>
            <Button
              className={BTN_CLASS}
              color={RADIX.COLOR.SUCCESS}
              onClick={() => this.handleUpdatePitch()}
            >
              {t('common.update-pitch')}
            </Button>
          </Box>
        )}

        <Box>
          <Button className={BTN_CLASS} onClick={() => this.handleAddPitch()}>
            {t('pd.add-to-pitch-list')}
          </Button>
        </Box>
      </Flex>
    );
  }
}
