export const HELP_URLS = {
  INTERCOM_LANDING: 'https://intercom.help/trajekt-sports/en/',

  // when machine performance fails
  MECH_CHECKLIST:
    'https://trajektsports.atlassian.net/wiki/spaces/CS/pages/174718978/Mechanical+Checklist',

  PITCH_UPLOADER:
    'https://intercom.help/trajekt-sports/en/articles/8284509-upload-pitches',

  // when user data (e.g. pitch lists) need to be reassigned to other users/machines/teams
  PITCH_REASSIGN:
    'https://intercom.help/trajekt-sports/en/articles/9096329-reassign-pitch-lists',

  RAPSODO_HOW_TO:
    'https://intercom.help/trajekt-sports/en/articles/8313059-rapsodo-setup',

  /** troubleshooting Rapsodo error messages */
  RAPSODO_ERRORS:
    'https://intercom.help/trajekt-sports/en/articles/8321528-rapsodo-errors',

  SETTINGS:
    'https://intercom.help/trajekt-sports/en/articles/9043471-use-settings-modal',

  SETTINGS_ACCESSORIES:
    'https://intercom.help/trajekt-sports/en/articles/8813360-setting-up-and-using-your-foot-pedal',

  SETTINGS_CONTINUOUS_TRAINING:
    'https://intercom.help/trajekt-sports/en/articles/9043427-how-to-continuous-training',

  VIDEO_LIBRARY:
    'https://intercom.help/trajekt-sports/en/articles/8288138-upload-pitcher-videos',
};

export const MISC_URLS = {
  /** what gets opened by demo toasts with links */
  TOAST_DEMO: 'https://random.org',
};

export const INTERCOM_ARTICLE_IDS = {
  MACHINE_DISCONNECTED: 8321311,
};
